import React, { useState, useRef, useEffect } from "react";
import { Auth } from "aws-amplify";
import { Container, MyButton as Button } from "./components";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import styles from "./styles";
import Box from "@mui/material/Box";

const baseUrl = "https://pow9rz7r6d.execute-api.us-west-2.amazonaws.com/dev";

const useEffectOnlyFirst = (fn, arr) => {
  const isFirst = useRef(false);

  useEffect(() => {
    if (isFirst.current) {
      return;
    } else {
      isFirst.current = true;
      return fn();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, arr);
};

const AlertList = ({ selectedClient }) => {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const classes = styles();

  const fetchListOfAlerts = async () => {
    setLoading(true);
    console.log(selectedClient);
    const user = await Auth.currentAuthenticatedUser();
    const endpointResp = await fetch(
      baseUrl + `/alert?ucid=${selectedClient || ""}`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: user.signInUserSession.idToken.jwtToken,
        }),
        cache: "no-cache",
      }
    );
    const json = await endpointResp.json();
    setData(json.body);
    setLoading(false);
  };

  const AcknowledgeAlert = async (alertID) => {
    setLoading(true);
    const user = await Auth.currentAuthenticatedUser();
    await fetch(baseUrl + `/alert/acknowledge?sessionid=${alertID}`, {
      method: "POST",
      headers: new Headers({
        Authorization: user.signInUserSession.idToken.jwtToken,
      }),
      cache: "no-cache",
    });
    fetchListOfAlerts();
    setLoading(false);
  };
  const getTableFromEntry = (entry) => {
    return (
      <TableRow key={`alert-row-${entry.Index}`}>
        <TableCell>{entry.Index}</TableCell>
        <TableCell>{entry.TitleText}</TableCell>
        <TableCell>{entry.Severity}</TableCell>
        <TableCell>{entry.AlertType}</TableCell>
        <TableCell>{entry.EventID}</TableCell>
        <TableCell>{entry.AlertID_msec}</TableCell>
        <TableCell>{entry.IsAcked || "Nobody"}</TableCell>
        <TableCell>
          <Button onClick={() => AcknowledgeAlert(entry.AlertID_str)}>
            Acknowledge
          </Button>
        </TableCell>
      </TableRow>
    );
  };

  useEffectOnlyFirst(fetchListOfAlerts, [data]);
  return (
    <>
      <Container>
        <Box m={2}>
          <Button onClick={fetchListOfAlerts}>Fetch Alerts</Button>
        </Box>
        {isLoading && <CircularProgress size={100} />}
        {!isLoading && !!data && (
          <>
            <TableContainer component={Paper}>
              <Table className={classes.table}>
                <TableHead key="thead">
                  <TableRow key="tr-head">
                    <TableCell>Index</TableCell>
                    <TableCell>TitleText</TableCell>
                    <TableCell>Severity</TableCell>
                    <TableCell>Event Type</TableCell>
                    <TableCell>Event ID</TableCell>
                    <TableCell>AlertID_msec</TableCell>
                    <TableCell>Seen by</TableCell>
                    <TableCell>Acknowledge</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{data.map((e) => getTableFromEntry(e))}</TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </Container>
    </>
  );
};

export default AlertList;
