import React from "react";

import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

const ReportChart = ({
  imageWidth,
  imageHeight,
  borderRadius,
  reportUrl,
  isLoading,
  ...rest
}) => {
  return (
    <Box sx={{ p: 2, width: imageWidth, height: imageHeight }}>
      <Box
        sx={{
          border: 1,
          borderColor: "lightgrey",
          borderRadius: 1,
          overflow: "hidden",
        }}
      >
        {isLoading ? (
          <Skeleton varient="" width={imageWidth} animation={isLoading} />
        ) : (
          <img
            src={reportUrl || ""}
            width={imageWidth}
            height={imageHeight}
            alt={""}
            {...rest}
          />
        )}
      </Box>
    </Box>
  );
};

export default ReportChart;
