import React, { useState } from "react";
import { Auth } from "aws-amplify";
import Fade from "@mui/material/Fade";
import Visibility from "@mui/icons-material/Visibility";
import packageJson from "../../package.json";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import MuiAlert from "@mui/material/Alert";
import {
  Container,
  Grid,
  CircularProgress,
  Typography,
  TextField,
  IconButton,
  InputLabel,
  InputAdornment,
  FormControl,
  OutlinedInput,
  Stack,
  Button,
  Box,
} from "@mui/material";

const Alert = React.forwardRef((props, ref) => {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export { Alert };

const SignIn = ({ setAuth }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [errorOpen, setErrorOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const trySignIn = async () => {
    setIsLoading(true);
    try {
      const user = await Auth.signIn(username, password);
      await setAuth(user);
    } catch (e) {
      setErrorOpen(true);
      setError(e.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleAlertClose = () => {
    setErrorOpen(false);
    setError("");
  };

  const handleMouseDown = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <Fade in={true} mountOnEnter unmountOnExit>
        <div>
          <Box sx={{ p: 10, display: "flex" }} />
          <Grid
            spacing={2}
            justifyContent="center"
            alignItems="center"
            container
          >
            <Box sx={{ width: 1000 }}>
              <Stack sx={{ width: "360" }} spacing={2}>
                {errorOpen && (
                  <Alert onClose={handleAlertClose} severity={"error"}>
                    <Typography>{error}</Typography>
                  </Alert>
                )}
              </Stack>
              <Grid
                item
                xs={12}
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
              >
                <img
                  width="302"
                  src="./caremate_logo.png"
                  alt="caremate logo"
                />

                <form noValidate>
                  <Container>
                    <Box sx={{ pt: 2, pb: 2 }}>
                      <TextField
                        id="username"
                        label="Username"
                        variant="outlined"
                        fullWidth
                        autoFocus
                        error={errorOpen}
                        onKeyPress={(event) => {
                          if (event.key === "Enter") {
                            trySignIn();
                          }
                        }}
                        value={username}
                        onChange={(event) => setUsername(event.target.value)}
                      />
                    </Box>
                    <Box sx={{ pt: 2, pb: 2 }}>
                      <FormControl variant="outlined">
                        <InputLabel htmlFor="password-input">
                          Password
                        </InputLabel>
                        <OutlinedInput
                          id="password-input"
                          label="Password"
                          autoComplete={"off"}
                          sx={{ width: "250px" }}
                          error={errorOpen}
                          type={showPassword ? "text" : "password"}
                          value={password}
                          onKeyPress={(event) => {
                            if (event.key === "Enter") {
                              trySignIn();
                            }
                          }}
                          onChange={(event) => {
                            setPassword(event.target.value);
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleShowPassword}
                                onMouseDown={handleMouseDown}
                                edge="end"
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        ></OutlinedInput>
                      </FormControl>
                    </Box>

                    <Stack
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                    >
                      <Box sx={{ width: "250px", pt: 2, pb: 2 }}>
                        <Button
                          disabled={!username || !password}
                          onClick={() => trySignIn()}
                          variant="contained"
                          fullWidth
                          size="large"
                        >
                          Login
                        </Button>
                      </Box>

                      <Box>
                        <Button sx={{ width: "250px" }} variant="text">
                          Forget Password
                        </Button>
                      </Box>
                    </Stack>
                  </Container>
                </form>
              </Grid>
              {isLoading && (
                <Grid
                  item
                  container
                  justifyContent={"center"}
                  alignContent={"center"}
                  xs={12}
                >
                  <CircularProgress size={100} />
                </Grid>
              )}
              <Grid container justifyContent="center">
                <Box sx={{ position: "absolute", bottom: 0 }}>
                  <Typography textAlign="center">{`version: ${packageJson.version}`}</Typography>
                </Box>
              </Grid>
            </Box>
          </Grid>
        </div>
      </Fade>
    </>
  );
};

export default SignIn;
