import React, { useState, useEffect, useCallback } from "react";
import { Auth } from "aws-amplify";
import { Container, MyButton as Button } from "./components";
import ClientNotficationConfig from "./notfications";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import styles from "./styles";
import CircularProgress from "@mui/material/CircularProgress";

const baseUrl = "https://pow9rz7r6d.execute-api.us-west-2.amazonaws.com/dev";

const ClientSummary = ({ selectedClient, setSelectedClient }) => {
  const [data, setData] = useState([]);
  const [Error, setError] = useState("");
  const [isLoading, setLoading] = useState(false);
  const classes = styles();

  const fetchClientSummary = useCallback(async () => {
    setLoading(true);
    const user = await Auth.currentAuthenticatedUser();
    const endpointResp = await fetch(
      baseUrl + `/client/summary?ucid=${selectedClient}`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: user.signInUserSession.idToken.jwtToken,
        }),
        cache: "no-cache",
      }
    );
    const json = await endpointResp.json();
    if ("Error" in json || "message" in json) {
      setData(undefined);
      setError(json?.Error || json?.message);
      setLoading(false);
    } else {
      setData(json.body[0]);
      setLoading(false);
    }
  }, [selectedClient]);
  const tableRowFromList = (list, tableHead) => {
    if (!!list) {
      const tableRows = list.map((entry) => (
        <TableRow key={`tr-${tableHead}-${entry.String}-${entry.Type}`}>
          <TableCell>{entry.String}</TableCell>
          <TableCell>{entry.Type}</TableCell>
          <TableCell>{entry.Arrow}</TableCell>
        </TableRow>
      ));
      return (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>String</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Arrow</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{tableRows}</TableBody>
          </Table>
        </TableContainer>
      );
    }
    return <></>;
  };
  useEffect(() => {
    // eslint-disable-next-line
    async function fetchData() {
      await fetchClientSummary();
    }
    fetchData();
  }, [fetchClientSummary, selectedClient]);

  if (!!Error) {
    console.log(Error);
    return <p>{Error}</p>;
  }
  return (
    <>
      <Container className={classes.container}>
        <Grid container justifyContent="center" spacing={3}>
          <Grid item xs={6}>
            <Button onClick={fetchClientSummary}>Fetch Client</Button>
          </Grid>
          <Grid item xs={6}>
            <form className={classes.root} noValidate autoComplete="off">
              <TextField
                className={classes.TextField}
                id="selectedClient"
                label="Client ID"
                value={selectedClient}
                variant="outlined"
                readOnly
              ></TextField>
            </form>
          </Grid>
          <Grid item xs={12}>
            {isLoading && <CircularProgress size={100} />}
            {!isLoading && !!data && (
              <>
                <br />
                <Typography variant="h5">
                  {`Name: ${data.Name}`}
                  <br />
                  {!!data?.PhotoUrl && (
                    <img src={data?.PhotoUrl || ""} alt="new" />
                  )}
                  <br />
                  {"Critical Alerts"}
                </Typography>
                {tableRowFromList(data.VitalsHighlights, "VitalsHiglights")}
                <br />
                <Typography variant="h5">{"ActivityHighlights"}</Typography>
                {tableRowFromList(
                  data.ActivityHighlights,
                  "ActivityHighlights"
                )}
                <br />
                <Typography variant="h5">{"YesterdayComparison"}</Typography>
                {tableRowFromList(
                  data.YesterdayComparison,
                  "YesterdayComparison"
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Container>
      <ClientNotficationConfig selectedClient={selectedClient} />
    </>
  );
};

export default ClientSummary;
