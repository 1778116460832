import React, { useState, useRef, useEffect, useCallback } from "react";
import { Auth } from "aws-amplify";
import { MyButton as Button } from "./components";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import MenuItem from "@mui/material/MenuItem";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import { green, red } from "@mui/material/colors";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "./alertcomponent";
import Skeleton from "@mui/material/Skeleton";
import styles from "./styles";

import NewGateway from "./newgateway";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";

const gateways = Array.from(Array(15).keys());

const baseUrl = "https://pow9rz7r6d.execute-api.us-west-2.amazonaws.com/dev";

const conformingstatuses = [
  "NotDetermined",
  "Conforming",
  "NonConforming",
  "Scrapped",
];

const useEffectOnlyFirst = (fn, arr) => {
  const isFirst = useRef(false);

  useEffect(() => {
    if (isFirst.current) {
      return;
    } else {
      isFirst.current = true;
      return fn();
    }
    // eslint-disable-next-line
  }, [arr]);
};

const GatewayList = () => {
  const [providers, setProviders] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState("");
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const [showNewGateway, setShowNewGateway] = useState(false);
  const [alertState, setAlertState] = useState({ shouldShow: false });

  const [selectedDevice, setSelectedDevice] = useState("");

  const [showConformModal, setShowConformModal] = useState(false);
  // const [conformdevice, setConformDevice] = useState("");
  const [deviceStatus, setDeviceStatus] = useState(conformingstatuses[0]);
  const [conformnotes, setConformNotes] = useState("");
  const [conformloading, setConfromLoading] = useState(false);

  const [showProviderModal, setShowProviderModal] = useState(false);
  const [newProvider, setNewProvider] = useState(0);
  const [providerloading, setProviderLoading] = useState(false);

  const [editMode, setEditMode] = useState(false);

  const classes = styles();

  const fetchListOfGateways = useCallback(
    async (shouldLoad) => {
      if (shouldLoad) setLoading(true);
      const user = await Auth.currentAuthenticatedUser();
      const endpointResp = await fetch(
        baseUrl + `/inventory/gateway?upid=${selectedProvider}&detailed=True`,
        {
          method: "GET",
          headers: new Headers({
            Authorization: user.signInUserSession.idToken.jwtToken,
          }),
          cache: "no-cache",
        }
      );
      const json = await endpointResp.json();
      setData(json.body);
      if (shouldLoad) setLoading(false);
    },
    [selectedProvider]
  );

  const updateConform = async (event) => {
    setConfromLoading(true);
    event.preventDefault();
    const user = await Auth.currentAuthenticatedUser();
    const endpointResp = await fetch(
      baseUrl + `/admin/superuser/gateway?gwid=${selectedDevice}`,
      {
        method: "PUT",
        headers: new Headers({
          Authorization: user.signInUserSession.idToken.jwtToken,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          notes: conformnotes,
          status: deviceStatus,
        }),
        cache: "no-cache",
      }
    );

    const json = await endpointResp.json();
    if ("Error" in json) {
      console.log(json.Error);
      setAlertState({
        message: json.Error,
        sev: "error",
        shouldShow: true,
      });
    } else {
      console.log(json.body[0]);
      if (json.body[0].WasSuccess) {
        setAlertState({
          message: "Conform Status Updated!",
          sev: "success",
          shouldShow: true,
        });
      }
      setConfromLoading(false);
      handleModalClose();
    }

    fetchListOfGateways(false);
  };

  const updateIsConform = async (event, ugid) => {
    event.preventDefault();
    const user = await Auth.currentAuthenticatedUser();
    await fetch(baseUrl + `/admin/superuser/gateway?gwid=${ugid}`, {
      method: "PUT",
      headers: new Headers({
        Authorization: user.signInUserSession.idToken.jwtToken,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        isconforming: !event.target.checked,
      }),
      cache: "no-cache",
    });
    fetchListOfGateways(false);
  };

  const updateDeviceProvider = async (ugid) => {
    setProviderLoading(true);
    const user = await Auth.currentAuthenticatedUser();
    const endpointResp = await fetch(
      baseUrl + `/admin/superuser/gateway?gwid=${ugid}`,
      {
        method: "PUT",
        headers: new Headers({
          Authorization: user.signInUserSession.idToken.jwtToken,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          newprovider: newProvider,
        }),
        cache: "no-cache",
      }
    );
    const json = await endpointResp.json();
    if ("Error" in json) {
      console.log(json.Error);
      setAlertState({
        message: json.Error,
        sev: "error",
        shouldShow: true,
      });
    } else {
      console.log(json.body[0]);
      if (json.body[0].WasSuccess) {
        setAlertState({
          message: "Provider Updated!",
          sev: "success",
          shouldShow: true,
        });
      } else {
        setAlertState({
          message: json.body[0].Message,
          sev: "warning",
          shouldShow: true,
        });
      }
    }
    fetchListOfGateways(false);
    setProviderLoading(false);
    handleProviderModalClose();
  };

  const fetchListOfProviders = async () => {
    setLoading(true);
    const user = await Auth.currentAuthenticatedUser();
    const endpointResp = await fetch(baseUrl + "/provider", {
      method: "GET",
      headers: new Headers({
        Authorization: user.signInUserSession.idToken.jwtToken,
      }),
      cache: "no-cache",
    });
    const json = await endpointResp.json();
    let providers = json.body;
    providers = providers.sort((a, b) => a.ProvID - b.ProvID);
    setProviders(providers);
    setSelectedProvider(providers[0].UPID);
    setLoading(false);
  };

  const handleProviderSelect = async (value) => {
    setSelectedProvider(value);
  };

  useEffect(() => {
    if (!selectedProvider.current) {
      setData([]);
    }
    fetchListOfGateways(true);
    return function cleanup() {
      setData([]);
    };
  }, [fetchListOfGateways, selectedProvider]);
  const getSkeletonFromEntry = (rowNum) => {
    return (
      <TableRow key={`skeleton-row-${rowNum}`}>
        <TableCell align="left" width={150}>
          <Skeleton />
        </TableCell>
        <TableCell align="right" width={100}>
          <Skeleton />
        </TableCell>
        <TableCell align="right" width={200}>
          <Skeleton />
        </TableCell>
        <TableCell align="right" width={200}>
          <Skeleton />
        </TableCell>
        <TableCell align="right" width={300}>
          <Skeleton />
        </TableCell>
        <TableCell align="right" width={50}>
          <Grid container justifyContent="center" alignItems="center">
            <Skeleton variant="circular" width={16} height={16} />
          </Grid>
        </TableCell>
        <TableCell align="right" width={120}>
          <Skeleton />
        </TableCell>
      </TableRow>
    );
  };
  const getTableFromEntry = (entry) => {
    const isConforming = entry?.IsConforming || false;
    const status = entry?.Quality?.ConformingStatus || conformingstatuses[0];
    return (
      <TableRow key={`gateway-row-${entry.Index}`}>
        <TableCell align="left" width={150}>
          {entry.GwID}
        </TableCell>
        {editMode && (
          <TableCell align="center" width={150}>
            <Button
              size={"small"}
              variant="text"
              onClick={() => handleProviderModal(entry.UGID)}
            >
              update
            </Button>
          </TableCell>
        )}
        {!editMode && (
          <TableCell align="right" width={80}>
            {entry.ProvID}
          </TableCell>
        )}
        <TableCell align="right" width={100}>
          {entry?.Product?.SerialNum}
        </TableCell>
        <TableCell align="right" width={200}>
          {entry.InstallNotes}
        </TableCell>
        <TableCell align="right" width={300}>
          {entry.UGID}
        </TableCell>
        <TableCell align="center" width={50}>
          {editMode && (
            <Switch
              size="small"
              onChange={(event) => {
                // console.log(event.target.checked);
                updateIsConform(event, entry.UGID);
              }}
              checked={isConforming}
              color="primary"
            />
          )}
          {!editMode && (
            <FiberManualRecordIcon
              style={{ color: isConforming ? green[500] : red[500] }}
            />
          )}
        </TableCell>
        <TableCell align="right" width={120}>
          {status}
        </TableCell>
        {editMode && (
          <TableCell align="left">
            <Button
              className={classes.button}
              onClick={() => handleModal(entry.UGID)}
              size="small"
              variant="text"
            >
              Update
            </Button>
          </TableCell>
        )}
      </TableRow>
    );
  };

  useEffectOnlyFirst(fetchListOfProviders, [providers]);

  const handleModal = (device) => {
    setSelectedDevice(device);
    setShowConformModal(true);
  };

  const handleProviderModal = (device) => {
    console.log("Handle Provider Modal");
    setSelectedDevice(device);
    setShowProviderModal(true);
    setProviderLoading(false);
  };

  const handleModalClose = () => {
    setSelectedDevice("");
    setConformNotes("");
    setDeviceStatus(conformingstatuses[0]);
    setConfromLoading(false);
    setShowConformModal(false);
  };
  const handleProviderModalClose = () => {
    setSelectedDevice("");
    setNewProvider(0);
    setShowProviderModal(false);
    setProviderLoading(false);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertState({ shouldShow: false });
  };
  if (showNewGateway) {
    return (
      <>
        <Grid
          container
          spacing={2}
          justifyContent="flex-start"
          alignContent="center"
        >
          <Grid item xs={12}>
            <Box m={2}>
              <Button
                onClick={() => setShowNewGateway(false)}
                className={classes.button}
                startIcon={<ChevronLeftIcon />}
                variant="outlined"
              >
                Back
              </Button>
            </Box>
          </Grid>
          <NewGateway
            cancel={() => {
              setShowNewGateway(false);
            }}
            refresh={() => fetchListOfGateways(false)}
            providers={providers}
          />
        </Grid>
      </>
    );
  }
  return (
    <>
      <Fade in={!showNewGateway} mountOnEnter unmountOnExit>
        <div>
          <Box sx={{ width: "80vw", height: "50px" }}>
            {alertState.shouldShow && (
              <Grid container justifyContent="center">
                <Box sx={{ width: "25%" }}>
                  <Alert onClose={handleAlertClose} severity={alertState.sev}>
                    <Typography>{alertState.message}</Typography>
                  </Alert>
                </Box>
              </Grid>
            )}
          </Box>
          <Dialog open={showConformModal} onClose={handleModalClose}>
            <DialogTitle>Update Device Quality</DialogTitle>
            <DialogContent>
              <>
                <Box m={2}>
                  <TextField
                    id="conform-device-status-select"
                    select
                    label="Status"
                    value={deviceStatus}
                    className={classes.textField}
                    onChange={(event) => setDeviceStatus(event.target.value)}
                  >
                    {conformingstatuses.map((status) => (
                      <MenuItem
                        key={`conform-device-option${status}`}
                        value={status}
                      >
                        {status}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
                <Box m={2}>
                  <TextField
                    id="confrom-notes-input"
                    label="Notes"
                    value={conformnotes}
                    onChange={(event) => setConformNotes(event.target.value)}
                    multiline
                    rows={4}
                    className={classes.textField}
                  ></TextField>
                </Box>
              </>
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" onClick={handleProviderModalClose}>
                Cancel
              </Button>

              <LoadingButton
                onClick={(event) => updateConform(event)}
                loading={conformloading}
                variant="contained"
              >
                Submit
              </LoadingButton>
            </DialogActions>
          </Dialog>

          <Dialog open={showProviderModal} onClose={handleProviderModalClose}>
            <DialogTitle>Assign New Provider</DialogTitle>
            <DialogContent>
              <Box m={2}>
                <TextField
                  id="Provider-device-status-select"
                  select
                  label="Provider"
                  value={newProvider}
                  className={classes.textField}
                  onChange={(event) => setNewProvider(event.target.value)}
                >
                  {providers.map((p) => (
                    <MenuItem
                      key={`provider-device-option${p.UPID}`}
                      value={p.ProvID}
                    >
                      {p.ProvID}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" onClick={handleProviderModalClose}>
                Cancel
              </Button>

              <LoadingButton
                onClick={() => updateDeviceProvider(selectedDevice)}
                loading={providerloading}
                variant="contained"
              >
                Submit
              </LoadingButton>
            </DialogActions>
          </Dialog>

          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
            direction="row"
          >
            <Box sx={{ p: 4, BackgroundColor: "red" }}>
              {!!providers && (
                <>
                  <FormControl className={classes.textField}>
                    <InputLabel id="dropdown-select-provider-label">
                      {"Provider"}
                    </InputLabel>
                    <Select
                      labelId="dropdown-select-provider-label"
                      id="dropdown-select-provider"
                      label="Provider"
                      value={`${selectedProvider}`}
                      onChange={(event) =>
                        handleProviderSelect(event.target.value)
                      }
                    >
                      {providers.map((option, index) => {
                        return (
                          <MenuItem
                            key={`dropdown-${index}-${option}`}
                            value={option.UPID}
                          >
                            {`${option.Name} (${option.ProvID})`}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </>
              )}
            </Box>

            <Box sx={{ p: 2 }}>
              <Button
                className={classes.button}
                onClick={() => setShowNewGateway(true)}
              >
                New
              </Button>
            </Box>
            {!editMode && (
              <Box m={2}>
                <Button
                  className={classes.button}
                  onClick={() => setEditMode(true)}
                  variant="outlined"
                >
                  Edit
                </Button>
              </Box>
            )}
            {editMode && (
              <Box m={2}>
                <Button
                  className={classes.button}
                  onClick={() => setEditMode(false)}
                >
                  Done
                </Button>
              </Box>
            )}
          </Grid>

          <Grid item container justifyContent="center">
            {isLoading && !!selectedProvider && (
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 1350 }}
                  size="small"
                  className={classes.table}
                >
                  <TableHead key="thead-skeliton">
                    <TableRow key="tr-head">
                      <TableCell align="left" width={150}>
                        Gateway ID
                      </TableCell>
                      <TableCell align="right" width={100}>
                        Provider ID
                      </TableCell>
                      <TableCell align="right" width={200}>
                        Serial Num
                      </TableCell>
                      <TableCell align="right" width={200}>
                        Notes
                      </TableCell>
                      <TableCell align="right" width={300}>
                        UGID
                      </TableCell>
                      <TableCell align="center" width={50}>
                        IsConforming
                      </TableCell>
                      <TableCell align="right" width={120}>
                        Conform status
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {gateways.map((e) => getSkeletonFromEntry(e))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            {!isLoading && data && (
              <>
                <TableContainer component={Paper}>
                  <Table
                    sx={{ minWidth: 1350 }}
                    size="small"
                    className={classes.table}
                  >
                    <TableHead key="thead">
                      <TableRow key="tr-head">
                        <TableCell align="left" width={150}>
                          Gateway ID
                        </TableCell>
                        <TableCell align="right" width={100}>
                          Provider ID
                        </TableCell>
                        <TableCell align="right" width={200}>
                          Serial Num
                        </TableCell>
                        <TableCell align="right" width={200}>
                          Notes
                        </TableCell>
                        <TableCell align="right" width={300}>
                          UGID
                        </TableCell>
                        <TableCell align="center" width={50}>
                          IsConforming
                        </TableCell>
                        <TableCell align="right" width={120}>
                          Conform status
                        </TableCell>
                        {editMode && <TableCell>Conform</TableCell>}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.map((e) => getTableFromEntry(e))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
          </Grid>
        </div>
      </Fade>
    </>
  );
};

export default GatewayList;
