import React, { useState, useRef, useEffect } from "react";
import { Auth } from "aws-amplify";
import { MyButton as Button } from "./components";
import RegisterUser from "./registeruser";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Typography from "@mui/material/Typography";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Fade from "@mui/material/Fade";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";

import styles from "./styles";

const users = Array.from(Array(15).keys());

const baseUrl = "https://pow9rz7r6d.execute-api.us-west-2.amazonaws.com/dev";

const useEffectOnlyFirst = (fn, arr) => {
  const isFirst = useRef(false);

  useEffect(() => {
    if (isFirst.current) {
      return;
    } else {
      isFirst.current = true;
      return fn();
    }
    // eslint-disable-next-line
  }, [arr]);
};

const UserList = ({ selectedUser, setSelectedUser }) => {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [showNewUser, setShowNewUser] = useState(false);

  const classes = styles();

  const fetchListOfUsers = async () => {
    setLoading(true);
    const user = await Auth.currentAuthenticatedUser();
    const endpointResp = await fetch(
      baseUrl + "/user/list?detailed=true&row_count=1000",
      {
        method: "GET",
        headers: new Headers({
          Authorization: user.signInUserSession.idToken.jwtToken,
        }),
        cache: "no-cache",
      }
    );
    const json = await endpointResp.json();
    setData(json.body);
    setLoading(false);
  };
  const getSkeletonFromEntry = (rowNum) => {
    return (
      <TableRow key={`skeleton-row-${rowNum}`}>
        <TableCell align="right">
          <Skeleton />
        </TableCell>
        <TableCell align="right">
          <Skeleton variant="rectangular" width="240px" height="36px" />
        </TableCell>
        <TableCell align="right">
          <Skeleton />
        </TableCell>
        <TableCell align="right">
          <Grid container justifyContent="right" alignItems="center">
            <Skeleton width="270px" />
          </Grid>
        </TableCell>
        <TableCell align="right">
          <Skeleton />
        </TableCell>
        <TableCell align="right">
          <Skeleton />
        </TableCell>
      </TableRow>
    );
  };
  const getTableFromEntry = (entry) => {
    return (
      <TableRow
        key={`user-row-${entry.Index}`}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell align="right">{entry.Index}</TableCell>
        <TableCell align="center">
          {selectedUser === entry.UUID && (
            <Box
              sx={{
                height: 30,
                mt: "10px",
              }}
            >
              <Typography variant="p">{`${entry.Name}`}</Typography>
            </Box>
          )}
          {selectedUser !== entry.UUID && (
            <Button
              sx={{ width: 240 }}
              variant="text"
              onClick={() => setSelectedUser(entry.UUID)}
            >
              {entry.Name}
            </Button>
          )}
        </TableCell>
        <TableCell align="right">{entry.ProvID}</TableCell>
        <TableCell align="right">{entry.UUID}</TableCell>
        <TableCell align="right">
          {entry?.DataAccessProvs?.join(", ")}
        </TableCell>
        <TableCell align="right">{entry?.Roles?.join(", ")}</TableCell>
      </TableRow>
    );
  };

  useEffectOnlyFirst(fetchListOfUsers, [data]);

  if (showNewUser) {
    return (
      <>
        <Grid container spacing={2} justfiy="left" alignContent="center">
          <Grid item xs={12}>
            <Box sx={{ p: 2 }}>
              <Button
                onClick={() => setShowNewUser(false)}
                className={classes.button}
                startIcon={<ChevronLeftIcon />}
                variant="outlined"
              >
                Back
              </Button>
            </Box>
          </Grid>

          <RegisterUser />
        </Grid>
      </>
    );
  }

  return (
    <Fade in={!showNewUser} mountOnEnter unmountOnExit>
      <div>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignContent="center"
        >
          <Box m={2}>
            <Button
              className={classes.button}
              onClick={() => setShowNewUser(true)}
            >
              Invite User
            </Button>
          </Box>

          {isLoading && (
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 984 }}
                size="small"
                className={classes.table}
              >
                <TableHead key="thead">
                  <TableRow key="tr-head">
                    <TableCell align="right" width={32}>
                      Index
                    </TableCell>
                    <TableCell align="center" width={200}>
                      Name
                    </TableCell>
                    <TableCell align="right" width={32}>
                      ProvID
                    </TableCell>
                    <TableCell align="right" width={300}>
                      UUID
                    </TableCell>
                    <TableCell align="right" width={300}>
                      Data Access Provs
                    </TableCell>
                    <TableCell align="right" width={120}>
                      Roles
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map((e) => getSkeletonFromEntry(e))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {!isLoading && !!data && (
            <>
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 984 }}
                  size="small"
                  className={classes.table}
                >
                  <TableHead key="thead">
                    <TableRow key="tr-head">
                      <TableCell align="right" width={32}>
                        Index
                      </TableCell>
                      <TableCell align="center" width={200}>
                        Name
                      </TableCell>
                      <TableCell align="right" width={32}>
                        ProvID
                      </TableCell>
                      <TableCell align="right" width={300}>
                        UUID
                      </TableCell>
                      <TableCell align="right" width={300}>
                        Data Access Provs
                      </TableCell>
                      <TableCell align="right" width={120}>
                        Roles
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{data.map((e) => getTableFromEntry(e))}</TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </Grid>
      </div>
    </Fade>
  );
};

export default UserList;
