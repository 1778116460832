import React, { useState, useRef, useEffect } from "react";
import { MyButton as Button } from "./components";

import styles from "./styles";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Alert from "./alertcomponent";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Skeleton from "@mui/material/Skeleton";

const providers = Array.from(Array(3).keys());

const baseUrl = "https://pow9rz7r6d.execute-api.us-west-2.amazonaws.com/dev";

const CmadConfig = ({ user }) => {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [newProvider, setNewProvider] = useState("0");
  const [alertState, setAlertState] = useState({ shouldShow: false });
  const classes = styles();

  const useEffectOnlyFirst = (fn, arr) => {
    const isFirst = useRef(false);

    useEffect(() => {
      if (isFirst.current) {
        return;
      } else {
        isFirst.current = true;
        return fn();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, arr);
  };

  const getConfig = async (shouldLoad) => {
    if (shouldLoad) setLoading(true);
    const endpointResp = await fetch(baseUrl + `/admin/superuser/provider`, {
      method: "GET",
      headers: new Headers({
        Authorization: user.signInUserSession.idToken.jwtToken,
      }),
      cache: "no-cache",
    });
    const json = await endpointResp.json();
    setData(json.body);
    if (shouldLoad) setLoading(false);
  };
  const fetchPutProvider = async (shouldDelete, provID) => {
    const deleteParam = !shouldDelete ? "" : "&remove=true";
    const endpointResp = await fetch(
      baseUrl + `/admin/superuser/provider?provider=${provID}${deleteParam}`,
      {
        method: "PUT",
        headers: new Headers({
          Authorization: user.signInUserSession.idToken.jwtToken,
        }),
        cache: "no-cache",
      }
    );
    const json = await endpointResp.json();
    if ("Error" in json) {
      const error = json.Error;

      setAlertState({
        message: error,
        sev: "error",
        shouldShow: true,
      });
    } else if (!json.body[0].WasSuccess) {
      setAlertState({
        message: json.body[0].Message,
        sev: "info",
        shouldShow: true,
      });
    } else {
      getConfig(false);
      if (json.body[0].WasSuccess) {
        if (shouldDelete) {
          setAlertState({
            message: "Removed Temperary Provider Access!",
            sev: "success",
            shouldShow: true,
          });
        } else {
          setAlertState({
            message: "Added Temperary Provider Access!",
            sev: "success",
            shouldShow: true,
          });
        }
      }
    }
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertState({ shouldShow: false, message: "" });
  };
  const getSkeletonFromEntry = (rowNum) => {
    return (
      <TableRow key={`skeleton-row-${rowNum}`}>
        <TableCell align="left" width={80}>
          <Skeleton />
        </TableCell>
        <TableCell align="right" width={140}>
          <Skeleton />
        </TableCell>
        <TableCell align="right" width={120}>
          <Skeleton />
        </TableCell>
      </TableRow>
    );
  };
  const getTableFromEntry = (entry) => {
    return (
      <TableRow key={`provider-temp-row-${entry.Index}`}>
        <TableCell align="left">{entry.ProvID}</TableCell>
        <TableCell align="right">{entry.Expiration}</TableCell>
        <TableCell>
          {
            <Grid container justifyContent="center" alignItems="center">
              <Button
                onClick={() => {
                  fetchPutProvider(true, entry.ProvID);
                }}
                variant="text"
              >
                Delete
              </Button>
            </Grid>
          }
        </TableCell>
      </TableRow>
    );
  };
  useEffectOnlyFirst(() => getConfig(true), [data]);
  return (
    <>
      <Grid container spacing={2} direction="row" justifyContent="center">
        <Box sx={{ width: "80vw", height: "50px", p: 2 }}>
          {alertState.shouldShow && (
            <Grid container justifyContent="center">
              <Box sx={{ width: "25%" }}>
                <Alert onClose={handleAlertClose} severity={alertState.sev}>
                  <Typography>{alertState.message}</Typography>
                </Alert>
              </Box>
            </Grid>
          )}
        </Box>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="center">
            <Box sx={{ p: 2 }}>
              <TextField
                className={classes.textField}
                id="input-provider"
                label="Provider ID"
                type="number"
                value={newProvider}
                onChange={(event) => setNewProvider(event.target.value)}
              />
            </Box>
            <Box sx={{ p: 2 }}>
              <Button
                className={classes.button}
                onClick={() => fetchPutProvider(false, newProvider)}
              >
                Add Provider
              </Button>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <>
            {isLoading && (
              <>
                <TableContainer component={Paper}>
                  <Table
                    size="small"
                    sx={{ minWidth: 340 }}
                    className={classes.table}
                  >
                    <TableHead key="thead-skeliton">
                      <TableRow key="tr-head">
                        <TableCell align="left" width={80}>
                          Provider ID
                        </TableCell>
                        <TableCell align="right" width={140}>
                          Expiration
                        </TableCell>
                        <TableCell align="center" width={120}>
                          Remove
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {providers.map((e) => getSkeletonFromEntry(e))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
            {!isLoading && !!data && (
              <>
                <TableContainer component={Paper}>
                  <Table
                    size="small"
                    sx={{ minWidth: 340 }}
                    className={classes.table}
                  >
                    <TableHead key="thead">
                      <TableRow key="tr-head">
                        <TableCell align="left" width={80}>
                          Provider ID
                        </TableCell>
                        <TableCell align="right" width={140}>
                          Expiration
                        </TableCell>
                        <TableCell align="center" width={120}>
                          Remove
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.map((e) => getTableFromEntry(e))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
          </>
        </Grid>
      </Grid>
    </>
  );
};

export default CmadConfig;
