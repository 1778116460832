import React, { useState, useRef, useEffect } from "react";

import SignIn from "./signin";

import { Auth } from "aws-amplify";

import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";

import AccountBoxIcon from "@mui/icons-material/AccountBox";
import SettingsIcon from "@mui/icons-material/Settings";
import PersonIcon from "@mui/icons-material/Person";
import ComputerIcon from "@mui/icons-material/Computer";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import AssignmentIcon from "@mui/icons-material/Assignment";
import HomeIcon from "@mui/icons-material/Home";
import LogoutIcon from "@mui/icons-material/Logout";

import UserList from "./userlist";
import ClientList from "./clientlist";
import ClientDetail from "./clientdetail";
import ClientSummary from "./summary";
import PhotoOps from "./photoops";
import ClientActivity from "./activity";
import GatewayList from "./gatewaylist";
import PatchList from "./patchlist";
import TestEndpoint from "./testendpoint";
import TrendingVitals from "./vitals";
import CmadConfig from "./cmadconfig";
import NewProvider from "./newprovider";
import ProviderTable from "./providerList";
import Reports from "./reports";

import AlertList from "./alert";
import AlertConfig from "./alertconfig";
import EcgTrace from "./ecgtrace";
import RoleOps from "./roles";

import styles from "./styles";

import packageJson from "../../package.json";

const baseUrl = "https://pow9rz7r6d.execute-api.us-west-2.amazonaws.com/dev";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ mt: "56px", p: 0, display: "flex" }}>{children}</Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Home = (props) => {
  const [user, setUserInfo] = useState({});
  const [value, setValue] = useState(0);
  const [selectedClient, setSelectedClient] = useState(
    "87e1bfcd-2854-3d71-a6fc-2a9776d385cb"
  );
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedNav, setSelectedNav] = useState("User");
  const [isSuperUser, setIsSuperUser] = useState(false);
  const classes = styles();

  let tabs = [
    "Client",
    "User",
    "Sensor",
    "Gateway",
    "Provider",
    "Reports",
    "Test",
  ];

  const useEffectOnlyFirst = (fn, arr) => {
    const isFirst = useRef(false);
    useEffect(() => {
      if (isFirst.current) {
        return;
      } else {
        isFirst.current = true;
        return fn();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [arr]);
  };

  const handleTab = (event, newValue) => {
    console.log("newValue: ", newValue);
    if (newValue === undefined) {
      setValue(0);
    } else {
      setValue(newValue);
    }
  };

  const onClientSelect = (newClient) => {
    console.log(newClient);
    setSelectedClient(newClient);
  };
  const onUserSelect = (newUser) => {
    console.log(newUser);
    setSelectedUser(newUser);
  };
  const handleSignOut = async () => {
    await Auth.signOut();
  };
  const handleListSelect = (option) => {
    setSelectedNav(option);
    console.log(option);
    handleTab(({}, 0));
  };
  const fetchUserInfo = async () => {
    const auth = await Auth.currentAuthenticatedUser();
    setUserInfo(auth);
    const endpointResp = await fetch(baseUrl + "/admin/roles", {
      method: "GET",
      headers: new Headers({
        Authorization: auth.signInUserSession.idToken.jwtToken,
      }),
      cache: "no-cache",
    });
    const json = await endpointResp.json();
    const roles = json.body[0];
    console.log("Roles", roles);
    if (roles.Message) {
      setIsSuperUser(false);
    } else if (roles.includes("Super")) {
      setIsSuperUser(true);
    }
  };
  useEffectOnlyFirst(fetchUserInfo, [user]);

  if (props.authState !== "signedIn") {
    return <SignIn />;
  }
  return (
    <Box sx={{ display: "flex" }}>
      <Drawer
        className={classes.drawer}
        sx={{
          width: 240,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: 240,
            boxSizing: "border-box",
          },
        }}
        variant="permanent"
        open
        anchor="left"
      >
        <div className={classes.toolbar}>
          <Box
            sx={{
              m: 2,
            }}
          >
            <Grid container alignContent="center" justifyContent="center">
              <img
                src="./caremate_logo.png"
                height="32"
                width="100"
                alt="caremate logo"
              />
            </Grid>
          </Box>

          <Divider />
          <List>
            <>
              {tabs.map((text, index) => {
                return (
                  <ListItem
                    button
                    key={text}
                    onClick={() => {
                      handleListSelect(text);
                    }}
                  >
                    <ListItemIcon>
                      {index === 0 ? (
                        <PersonIcon />
                      ) : index === 1 ? (
                        <AccountBoxIcon />
                      ) : index === 2 ? (
                        <PhoneAndroidIcon />
                      ) : index === 3 ? (
                        <ComputerIcon />
                      ) : index === 4 ? (
                        <HomeIcon />
                      ) : index === 5 ? (
                        <AssignmentIcon />
                      ) : (
                        <SettingsIcon />
                      )}
                    </ListItemIcon>
                    <ListItemText primary={text} />
                  </ListItem>
                );
              })}
              {isSuperUser && (
                <>
                  <Divider />
                  <ListItem
                    button
                    key={"Admin"}
                    onClick={() => {
                      handleListSelect("Admin");
                    }}
                  >
                    <ListItemIcon>
                      <SupervisorAccountIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Admin"} />
                  </ListItem>
                </>
              )}
              {
                <>
                  <Divider />
                  <ListItem
                    button
                    key={"Sign Out"}
                    onClick={() => handleSignOut()}
                  >
                    <ListItemIcon>
                      <LogoutIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Sign Out"}></ListItemText>
                  </ListItem>
                </>
              }
              <Box sx={{ bottom: "10px", left: "70px", position: "fixed" }}>
                <Typography>{`Version: ${packageJson.version}`}</Typography>
              </Box>
            </>
          </List>
        </div>
      </Drawer>

      <AppBar
        position="fixed"
        sx={{ width: { sm: `calc(100% - 240px)` }, ml: { sm: `240px` } }}
      >
        <Toolbar>
          {selectedNav === "Client" && (
            <Tabs
              value={value}
              onChange={handleTab}
              aria-label="Nav tabs"
              textColor="white"
            >
              <Tab label="Client List" {...a11yProps(0)} />
              <Tab label="Client Detail List" {...a11yProps(1)} />
              <Tab label="Client Photo Upload" {...a11yProps(2)} />
              <Tab label="Client Summary" {...a11yProps(3)} />
              <Tab label="Client Activity" {...a11yProps(4)} />
              <Tab label="Alert List" {...a11yProps(5)} />
              <Tab label="Alert config" {...a11yProps(6)} />
              <Tab label="ECG" {...a11yProps(7)} />
              <Tab label="Vitals" {...a11yProps(8)} />
            </Tabs>
          )}
          {selectedNav === "User" && (
            <Tabs
              value={value}
              onChange={handleTab}
              aria-label="Nav tabs"
              textColor="white"
            >
              <Tab label="User List" {...a11yProps(7)} />
              <Tab label="Roles" {...a11yProps(8)} />
            </Tabs>
          )}
          {selectedNav === "Sensor" && (
            <Tabs
              value={value}
              onChange={handleTab}
              aria-label="Nav tabs"
              textColor="white"
            >
              <Tab label="Sensor" {...a11yProps(9)} />
            </Tabs>
          )}
          {selectedNav === "Gateway" && (
            <Tabs
              value={value}
              onChange={handleTab}
              aria-label="Nav tabs"
              textColor="white"
            >
              <Tab label="Gateway" {...a11yProps(10)} />
            </Tabs>
          )}
          {selectedNav === "Provider" && (
            <Tabs
              value={value}
              onChange={handleTab}
              aria-label="Nav tabs"
              textColor="white"
            >
              <Tab label="Table" {...a11yProps(0)} />
              <Tab label="New Provider" {...a11yProps(1)} />
            </Tabs>
          )}
          {selectedNav === "Admin" && (
            <Tabs
              value={value}
              onChange={handleTab}
              aria-label="Nav tabs"
              textColor="white"
            >
              <Tab label="Admin" {...a11yProps(11)} />
            </Tabs>
          )}
        </Toolbar>
      </AppBar>

      <Box
        sx={{
          flexGrow: 1,
          bgcolor: "background.default",
          p: 3,
        }}
      >
        {selectedNav === "Client" && (
          <>
            <TabPanel value={value} index={0}>
              <ClientList
                selectedClient={selectedClient}
                setSelectedClient={onClientSelect}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <ClientDetail
                selectedClient={selectedClient}
                setSelectedClient={onClientSelect}
              />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <PhotoOps
                selectedClient={selectedClient}
                setSelectedClient={onClientSelect}
              />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <>
                <ClientSummary
                  selectedClient={selectedClient}
                  setSelectedClient={onClientSelect}
                />
              </>
            </TabPanel>
            <TabPanel value={value} index={4}>
              <ClientActivity
                selectedClient={selectedClient}
                setSelectedClient={onClientSelect}
              />
            </TabPanel>
            <TabPanel value={value} index={5}>
              <AlertList selectedClient={selectedClient} />
            </TabPanel>
            <TabPanel value={value} index={6}>
              <AlertConfig selectedClient={selectedClient} />
            </TabPanel>

            <TabPanel value={value} index={7}>
              <EcgTrace selectedClient={selectedClient} />
            </TabPanel>
            <TabPanel value={value} index={8}>
              <TrendingVitals selectedClient={selectedClient} />
            </TabPanel>
          </>
        )}
        {selectedNav === "User" && (
          <>
            <TabPanel value={value} index={0}>
              <UserList
                selectedUser={selectedUser}
                setSelectedUser={onUserSelect}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <RoleOps selectedUser={selectedUser} onSelectRole={() => {}} />
            </TabPanel>
          </>
        )}
        {selectedNav === "Sensor" && (
          <>
            <TabPanel value={value} index={0}>
              <PatchList />
            </TabPanel>
          </>
        )}
        {selectedNav === "Gateway" && (
          <>
            <TabPanel value={value} index={0}>
              <GatewayList />
            </TabPanel>
          </>
        )}
        {selectedNav === "Test" && (
          <>
            <TabPanel value={value} index={0}>
              <TestEndpoint />
            </TabPanel>
          </>
        )}
        {selectedNav === "Admin" && (
          <>
            <TabPanel value={value} index={0}>
              <CmadConfig user={user} />
            </TabPanel>
          </>
        )}
        {selectedNav === "Provider" && (
          <>
            <TabPanel value={value} index={0}>
              <ProviderTable />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <NewProvider />
            </TabPanel>
          </>
        )}
        {selectedNav === "Reports" && (
          <>
            <TabPanel value={value} index={0}>
              <Reports />
            </TabPanel>
          </>
        )}
      </Box>
    </Box>
  );
};

export default Home;
