import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Collapse from "@mui/material/Collapse";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import CircularProgress from "@mui/material/CircularProgress";

const baseUrl = "https://pow9rz7r6d.execute-api.us-west-2.amazonaws.com/dev";

const formatAddress = (address) => {
  if (address === undefined || address === {}) {
    return "---";
  }
  return `${address?.Address1 || ""}, ${
    address?.Address2 ? `${address?.Address2},` : ""
  } ${address?.City || ""} ${address?.State || ""}, ${address?.ZipCode || ""}`;
};
function Row(props) {
  const { provider } = props;
  const [open, setOpen] = React.useState(false);

  // ProjectionExpression="GatewayCount,Modified_msec,Notes,#TZ,ClientCount,#TY",
  //           ExpressionAttributeNames={"#N": "Name", "#TZ": "TimeZone", "#TY": "type"},
  return (
    <React.Fragment key={`${provider.ProvID}-frag`}>
      <TableRow key={`${provider.ProvID}-row-1`}>
        <TableCell key={`${provider.ProvID}-cell-arrow`}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          key={`${provider.ProvID}-cell-provid`}
        >
          {provider.ProvID}
        </TableCell>
        <TableCell
          key={`${provider.ProvID}-name`}
        >{`${provider.Name}`}</TableCell>
        <TableCell
          key={`${provider.ProvID}-cell-notes`}
        >{`${provider?.Notes}`}</TableCell>
      </TableRow>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        key={`${provider.ProvID}-row-2`}
      >
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={4}
          key={`${provider.ProvID}-cell-expandable`}
        >
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
            key={`${provider.ProvID}-collapse`}
          >
            <Box sx={{ margin: 2 }} key={`${provider.ProvID}-box-row`}>
              <>
                <Grid container justifyContent="center" direction="column">
                  <Stack direction="row" alignItems="baseline" spacing={2}>
                    <Typography variant="h5">{"Address: "}</Typography>
                    <Typography variant="body1">
                      {formatAddress(provider?.Address)}
                    </Typography>
                  </Stack>
                  <Stack direction="row" alignItems="baseline" spacing={2}>
                    <Typography variant="h5">{"GatewayCount: "}</Typography>
                    <Typography variant="body1">
                      {provider?.GatewayCount || "---"}
                    </Typography>
                  </Stack>
                  <Stack direction="row" alignItems="baseline" spacing={2}>
                    <Typography variant="h5">{"Created [msec]: "}</Typography>
                    <Typography variant="body1">
                      {provider?.Created_msec || "---"}
                    </Typography>
                  </Stack>
                  <Stack direction="row" alignItems="baseline" spacing={2}>
                    <Typography variant="h5">
                      {"Alert Sensitivity: "}
                    </Typography>
                    <Typography variant="body1">
                      {provider?.AlertSensitivity || "---"}
                    </Typography>
                  </Stack>
                  <Stack direction="row" alignItems="baseline" spacing={2}>
                    <Typography variant="h5">{"Is Enabled: "}</Typography>
                    <Typography variant="body1">
                      {provider?.IsEnabled ? "True" : "False" || "True"}
                    </Typography>
                  </Stack>
                  <Stack direction="row" alignItems="baseline" spacing={2}>
                    <Typography
                      variant="h5"
                      key={`${provider.ProvID}-UPID-key`}
                    >
                      {"UPID: "}
                    </Typography>
                    <Typography variant="body1">
                      {provider?.UPID || "---"}
                    </Typography>
                  </Stack>
                </Grid>
              </>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const ProviderTable = () => {
  const [isLoading, setLoading] = useState(false);
  const [providers, setProviders] = useState([]);
  const [filteredProviders, setFilteredProviders] = useState([]);
  const [filter, setFilter] = useState([]);

  const fetchProviders = async () => {
    setLoading(true);

    const user = await Auth.currentAuthenticatedUser();
    const uuid_result = await fetch(
      baseUrl + "/admin/superuser/provider/list",
      {
        method: "GET",
        headers: new Headers({
          Authorization: user.signInUserSession.idToken.jwtToken,
        }),
        cache: "no-cache",
      }
    );
    const json = await uuid_result.json();
    setProviders(json.body);
    setLoading(false);
  };

  useEffect(() => {
    fetchProviders();
  }, []);

  useEffect(() => {
    const filterProvider = (provider) => {
      const address = formatAddress(provider.Address);
      if (filter === "") {
        return true;
      } else {
        if (provider?.Name?.includes(filter)) {
          return true;
        } else if (address.includes(filter)) {
          return true;
        } else if (`${provider.ProvID}`.includes(filter)) {
          return true;
        } else if (`${provider.UPID}`.includes(filter)) {
          return true;
        }
      }
      return false;
    };

    const filtered = providers.filter(filterProvider);
    setFilteredProviders(filtered);
  }, [filter, providers]);

  const sorted = filteredProviders.sort((a, b) => a.ProvID - b.ProvID);

  return (
    <>
      <Grid container justifyContent="center">
        {isLoading && (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            height="90vh"
          >
            <CircularProgress size={150} />
          </Grid>
        )}
        {!isLoading && (
          <>
            <Box sx={{ pt: 2, pb: 2, width: "50ch" }}>
              <TextField
                id="name-input"
                label="Search Provider"
                fullWidth
                value={filter}
                placeholder="Search by Address, ProvID, Name, or UPID"
                InputProps={{
                  endAdornment: filter && (
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => setFilter("")}
                    >
                      <ClearIcon />
                    </IconButton>
                  ),
                }}
                onChange={(event) => setFilter(event.target.value)}
              />
            </Box>
            <Box sx={{ pt: 2 }}>
              <TableContainer component={Paper} sx={{ width: "80vw" }}>
                <Table sx={{ minWidth: 800 }} size="small">
                  <TableHead key="thead">
                    <TableRow>
                      <TableCell width={25} />
                      <TableCell width={100}>ProviderID</TableCell>
                      <TableCell width={200}>Name</TableCell>

                      <TableCell>Notes</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sorted.map((p) => {
                      return (
                        // <TableRow>
                        //   <TableCell>{`${p.ProvID}`}</TableCell>
                        //   <TableCell>{`${p.Name}`}</TableCell>
                        //   <TableCell>{`${p?.Notes}`}</TableCell>
                        // </TableRow>
                        <Row provider={p} key={`row-${p.ProvID}`}></Row>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </>
        )}
      </Grid>
    </>
  );
};
export default ProviderTable;
