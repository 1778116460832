import React, { useState, useRef, useEffect, useCallback } from "react";
import { Auth } from "aws-amplify";
import { Container, MyButton as Button } from "./components";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import styles from "./styles";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

const baseUrl = "https://pow9rz7r6d.execute-api.us-west-2.amazonaws.com/dev";

const ClientActivity = ({ selectedClient, setSelectedClient }) => {
  const [data, setData] = useState(undefined);
  const [isLoading, setLoading] = useState(false);
  const classes = styles();

  const useEffectOnlyFirst = (fn, arr) => {
    const isFirst = useRef(false);

    useEffect(() => {
      if (isFirst.current) {
        return;
      } else {
        isFirst.current = true;
        return fn();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, arr);
  };

  const fetchClientActivity = useCallback(async () => {
    setLoading(true);
    setData(undefined);
    const user = await Auth.currentAuthenticatedUser();
    const endpointResp = await fetch(
      baseUrl + `/client/activity?ucid=${selectedClient}`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: user.signInUserSession.idToken.jwtToken,
        }),
        cache: "no-cache",
      }
    );
    const json = await endpointResp.json();
    if ("Message" in json) {
      setData(undefined);
      setLoading(false);
      return;
    }
    setData(json.body[0]);
    setLoading(false);
  }, [selectedClient]);
  const tableRowFromListHourlyData = (list, dict) => {
    if (!!list && !!dict) {
      const tableRows = list.map((entry) => (
        <TableCell key={`th-${entry}`}>{entry}</TableCell>
      ));
      tableRows.unshift(<TableCell key={`th-time`}>{"Time"}</TableCell>);
      const tableData = Object.keys(dict).map((entry, index) => {
        const t_d = dict[entry].map((e) => e);
        t_d.unshift(entry);
        return (
          <TableRow key={`tr-${entry}-${index}`}>
            {t_d.map((e, index) => (
              <TableCell key={`hourlyData-${e}-${index}`}>{e}</TableCell>
            ))}
          </TableRow>
        );
      });

      return (
        <TableContainer component={Paper}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>{tableRows}</TableRow>
            </TableHead>
            <TableBody>{tableData}</TableBody>
          </Table>
        </TableContainer>
      );
    }
    return <></>;
  };
  const tableRowFromList = (list) => {
    if (!!list) {
      const tableRows = list.map((entry) => (
        <TableRow key={`tr-${entry.Index}`}>
          <TableCell>{entry.Type}</TableCell>
          <TableCell>{entry.Start_hrs}</TableCell>
          <TableCell>{entry.End_hrs}</TableCell>
        </TableRow>
      ));
      return (
        <TableContainer component={Paper}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Type</TableCell>
                <TableCell>Start</TableCell>
                <TableCell>End</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{tableRows}</TableBody>
          </Table>
        </TableContainer>
      );
    }
    return <></>;
  };
  useEffectOnlyFirst(fetchClientActivity, [data]);
  useEffect(() => {
    async function getData() {
      setSelectedClient(selectedClient);
      await fetchClientActivity();
    }
    getData();
  }, [fetchClientActivity, selectedClient, setSelectedClient]);

  return (
    <>
      <Container>
        <Box m={2}>
          <Button onClick={fetchClientActivity}>Fetch Client</Button>
        </Box>
        {isLoading && <CircularProgress size={100} />}
        {!isLoading && (
          <form className={classes.root} noValidate autoComplete="off">
            <Box m={2}>
              <TextField
                className={classes.textField}
                id="clientID"
                label="Client ID"
                variant="outlined"
                value={selectedClient}
                onChange={(event) => setSelectedClient(event.target.value)}
              />
            </Box>
          </form>
        )}
        {!isLoading && !!data && (
          <>
            <br />
            <Typography>
              {`Start_hrs: ${data.SectorConfig.Start_hrs}`}
              <br />
              {`End_hrs: ${data.SectorConfig.End_hrs}`}
              <br />
              {`Date: ${data.SectorConfig.Date_str}`}
              <br />
              <br />
            </Typography>
            <Typography variant="h3">{"Sector Data"}</Typography>
            {tableRowFromList(data.SectorData)}
            <Typography variant="h3">{"Hourly Data"}</Typography>
            {tableRowFromListHourlyData(data.HourlyConfig, data.HourlyData)}
          </>
        )}
      </Container>
    </>
  );
};

export default ClientActivity;
