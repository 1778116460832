import "./App.css";
import React from "react";
import Home from "./components/index";
import { Authenticator } from "aws-amplify-react";
import theme from "./components/theme";
import { ThemeProvider } from "@mui/material/styles";

const App = () => {
  return (
    <div>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
      />
      <ThemeProvider theme={theme}>
        <Authenticator
          hideDefault={true}
          onStateChange={(authState) => console.log(authState)}
        >
          <Home />
        </Authenticator>
      </ThemeProvider>
    </div>
  );
};

export default App;
