import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { MyButton as Button } from "./components";
import styles from "./styles";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import RoleOps from "./roles";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "./alertcomponent";

import Fade from "@mui/material/Fade";

import TimeZoneSelect from "./timezone";

const baseUrl = "https://pow9rz7r6d.execute-api.us-west-2.amazonaws.com/dev";

const RegisterUser = () => {
  const [NewEmail, setNewEmail] = useState("");
  const [NewPassword, setNewPassword] = useState("");
  const [First, setFirst] = useState("");
  const [Last, setLast] = useState("");
  const [provider, setProvider] = useState(0);
  const [TimeZone, setTime] = useState("America/Los_Angeles");
  const [loading, setLoading] = useState(false);
  const [selectedRole, setSelectedRole] = useState("");

  const [alertState, setAlertState] = useState({ shouldShow: false });

  const classes = styles();

  const SignUpUser = async () => {
    setLoading(true);
    const user = await Auth.currentAuthenticatedUser();
    const upload = await fetch(baseUrl + "/admin/superuser/create", {
      method: "PUT",
      headers: new Headers({
        Authorization: user.signInUserSession.idToken.jwtToken,
        "Content-Type": "application/json",
      }),
      cache: "no-cache",
      body: JSON.stringify({
        Email: NewEmail,
        password: NewPassword,
        First: First,
        Last: Last,
        Provider: provider,
        Role: selectedRole,
        TimeZone: TimeZone,
      }),
    });
    const json = await upload.json();
    if (json.body[0].WasSuccess) {
      setAlertState({
        message: json.body[0].Message,
        sev: "success",
        shouldShow: true,
      });
    } else if ("Message" in json.body[0]) {
      setAlertState({
        message: json.body[0].Message,
        sev: "error",
        shouldShow: true,
      });
    } else {
      console.log(json.body[0]);
    }
    resetForm();
    setLoading(false);
  };

  const resetForm = () => {
    setNewEmail("");
    setNewPassword("");
    setFirst("");
    setLast("");
    setProvider(0);
    setTime("America/Los_Angeles");
    setSelectedRole("Caregiver");
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertState({ shouldShow: false });
  };

  return (
    <>
      <Fade in={true} mountOnEnter unmountOnExit>
        <div className={classes.paperContent}>
          <Box sx={{ width: "80vw", height: "50px", p: 2 }}>
            {alertState.shouldShow && (
              <Grid container justifyContent="center">
                <Box sx={{ width: "25%" }}>
                  <Alert onClose={handleAlertClose} severity={alertState.sev}>
                    <Typography>{alertState.message}</Typography>
                  </Alert>
                </Box>
              </Grid>
            )}
          </Box>
          <form noValidate autoComplete="off">
            <Paper sx={{ width: "80vw" }} elevation={5}>
              <Grid container justifyContent="center" alignContent="center">
                <Box sx={{ p: 2 }}>
                  <Typography variant="h3">Invite User</Typography>
                </Box>
                <Grid container item justifyContent="center">
                  <Box sx={{ p: 2 }}>
                    <TextField
                      className={classes.textField}
                      id="input-first"
                      label="First Name"
                      value={First}
                      onChange={(event) => setFirst(event.target.value)}
                    />
                  </Box>
                  <Box sx={{ p: 2 }}>
                    <TextField
                      className={classes.textField}
                      id="input-Last"
                      label="Last Name"
                      value={Last}
                      onChange={(event) => setLast(event.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid container item justifyContent="center">
                  <Box sx={{ p: 2 }}>
                    <TextField
                      className={classes.textField}
                      id="input-email"
                      label="email"
                      value={NewEmail}
                      onChange={(event) => setNewEmail(event.target.value)}
                    />
                  </Box>

                  <Box sx={{ p: 2 }}>
                    <TextField
                      className={classes.textField}
                      id="input-password"
                      label="password"
                      value={NewPassword}
                      onChange={(event) => setNewPassword(event.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid container item justifyContent="center">
                  <Box sx={{ p: 2 }}>
                    <TextField
                      className={classes.textField}
                      id="input-Provider"
                      label="Provider"
                      error={provider < 0}
                      type="number"
                      value={provider}
                      onChange={(event) => setProvider(event.target.value)}
                    />
                  </Box>

                  <RoleOps
                    selectedUser={""}
                    onSelectRole={setSelectedRole}
                    value={selectedRole}
                  />
                </Grid>
                <Grid container item justifyContent="center">
                  <TimeZoneSelect onSelect={(newTZ) => setTime(newTZ)} />
                </Grid>
                <Box sx={{ p: 2 }}>
                  <Button
                    sx={{ height: 50 }}
                    size="large"
                    onClick={resetForm}
                    color="error"
                    variant="outlined"
                  >
                    Reset
                  </Button>
                </Box>
                <Box sx={{ p: 2 }}>
                  <Button sx={{ height: 50 }} size="large" onClick={SignUpUser}>
                    Register User
                  </Button>
                </Box>
              </Grid>
              <Box sx={{ p: 2 }} />
            </Paper>
          </form>
          <Grid item>
            {loading && (
              <Box sx={{ p: 2 }}>
                <CircularProgress size={100} />
              </Box>
            )}
          </Grid>
        </div>
      </Fade>
    </>
  );
};

export default RegisterUser;
