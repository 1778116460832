import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Amplify from "aws-amplify";

Amplify.configure({
    Auth: {
        region: "us-west-2",
        userPoolId: "us-west-2_wpwbIoi75",
        userPoolWebClientId: "77th1mi4kn0b9ehh8rd3mirej2"
    }
});

ReactDOM.render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
