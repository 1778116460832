import { makeStyles } from "@mui/styles";

const drawerWidth = 240;
export const styles = makeStyles((theme) => ({
  // alert: {

  // },
  root: {
    position: "relative",
  },
  DrawerMargin: {
    marginLeft: drawerWidth,
    marginTop: 25,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  container: {
    margin: theme.spacing(1),
  },
  stickToBottom: {
    width: "100%",
    position: "fixed",
    bottom: 0,
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    textAlign: "center",
    maxWidth: 600,
  },

  paperContent: {
    margin: "20",
    padding: theme.spacing(2),
    minHeight: "30vh",
    textAlign: "center",
    width: "90vw",
  },
  ecgChart: {
    maxWidth: "400px",
  },
  ecgBlock: {
    padding: theme.spacing(2),
    maxWidth: "600px",
  },
  modal: {
    top: "50%",
    left: "50%",
    transform: `translate(-50%, -50%)`,
  },
  modalbottom: {
    position: "absolute",
    top: "80%",
    left: "50%",
    transform: `translate(-50%, -50%)`,
    "margin-top": "25px",
  },
  modalPaper: {
    position: "absolute",
    width: 400,
    height: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    left: "50%",
    top: "50%",
    transform: `translate(-50%, -50%)`,
  },

  smallTextField: {
    width: "12ch",
  },
  textField: {
    width: "25ch",
  },
  uuidtextField: {
    width: "37ch",
  },
  endpointTextField: {
    width: "50ch",
  },

  mapViewScale: {
    position: "absolute",
    bottom: 100,
  },
  mapButton: {
    maxWidth: "25vh",
    left: 0,
    top: 1,
    margin: "auto",
  },
  fileInput: {
    display: "none",
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hide: {
    display: "None",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  Content: {
    marginTop: 50,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  imageAvatar: {
    width: 75,
    height: 75,
  },
}));

export const classes = {
  root: {
    position: "relative",
  },
  menuButton: {
    marginRight: 20,
  },
  button: {
    margin: 10,
  },
  container: {
    margin: 10,
  },
  stickToBottom: {
    width: "100%",
    position: "fixed",
    bottom: 0,
  },
  paperContent: {
    margin: 20,
    minHeight: "8vh",
  },
  paperContentMini: {
    margin: 10,
    minHeight: "8vh",
  },
  paper: {
    position: "fixed",
    top: "50%",
    transform: "translateY(-50%)",
    minHeight: "60vh",
    minWidth: "30vh",
  },
  subtitle: {
    colorPrimary: "red",
  },
  mapViewScale: {
    position: "absolute",
    bottom: 100,
  },
};

export default styles;
