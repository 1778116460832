import React from "react";
import styled from "styled-components";
import Button from "@mui/material/Button";

export const MyButton = (props) => {
  return (
    <Button
      sx={{ m: 1, p: 2, width: 120, height: 40 }}
      variant="contained"
      color="primary"
      {...props}
    />
  );
};
export const Table = styled.table`
  text-align: center;
  width: calc(100% - 1em);
`;

export const Input = styled.input`
  padding: 0.5em;
  margin: 0.5em;
  width: 10.5em;
`;
export const Label = styled.label`
  padding: 0.5em;
  margin: 0.5em;
  width: 5em;
`;
export const Container = styled.div`
  text-align: center;
`;

export const Tab = styled.div`
  width: 100%;
`;
export const TabsDiv = styled.div`
  background: #fff;
  font-family: Open Sans;
  height: 3em;
`;

export const TabButton = styled.button`
  border: none;
  flex: 1 1 33.33%;
  outline: none;
  cursor: pointer;
  position: relative;
  margin-right: 0.1em;
  font-size: 1em;
  border: ${(props) => (props.active ? "1px solid #ccc" : "")};
  border-bottom: ${(props) => (props.active ? "none" : "")};
  background-color: ${(props) => (props.active ? "white" : "lightgray")};
  height: ${(props) => (props.active ? "3em" : "2.6em; top:.4em")};
  transition: background-color 0.5s ease-in-out;
  :hover {
    background-color: white;
  }
`;
export const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 42px;
  height: 26px;
  border-radius: 15px;
  background: #bebebe;
  cursor: pointer;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin: 3px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;
export const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 42px;
  height: 26px;
  &:checked + ${CheckBoxLabel} {
    background: #4fbe79;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      margin-left: 21px;
      transition: 0.2s;
    }
  }
`;

export const Content = styled.div`
  ${(props) => (props.active ? "" : "display:none")}
`;
