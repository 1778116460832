import React, { useState, useEffect, useCallback } from "react";
import { Auth } from "aws-amplify";
import { Container, MyButton as Button } from "./components";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import styles from "./styles";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import AutoComplete from "./autocomplete";

const baseUrl = "https://pow9rz7r6d.execute-api.us-west-2.amazonaws.com/dev";

const TestEndpoint = () => {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [endpoint, setEndpoint] = useState(
    "/client/vitals/daily?mode=data&ucid=e36344b9-bb02-4b27-b464-7a80ALEXALEX&time_from=1620058031546&duration=1800"
  );
  const [selectedPlace, setSelectedPlace] = useState();
  const classes = styles();

  const testEndpoint = useCallback(async () => {
    setLoading(true);
    const user = await Auth.currentAuthenticatedUser();
    const endpointResp = await fetch(baseUrl + endpoint, {
      method: "GET",
      headers: new Headers({
        Authorization: user.signInUserSession.idToken.jwtToken,
      }),
      cache: "no-cache",
    });
    const json = await endpointResp.json();
    setData(json.body);
    console.log(json.body);
    setLoading(false);
  }, [endpoint]);

  const generateUCID = (type) => {
    setEndpoint(`/admin/superuser/uuid?type=${type}&count=1`);
    // testEndpoint();
  };

  useEffect(() => {
    if (endpoint.includes("uuid")) {
      testEndpoint();
    }
  }, [endpoint, testEndpoint]);

  const handleNewPlace = async (placeID) => {
    setSelectedPlace(placeID);
    if (!!placeID && placeID !== undefined) {
      const user = await Auth.currentAuthenticatedUser();
      const endpointResp = await fetch(baseUrl + `/place?place=${placeID}`, {
        method: "GET",
        headers: new Headers({
          Authorization: user.signInUserSession.idToken.jwtToken,
        }),
        cache: "no-cache",
      });
      const json = await endpointResp.json();
      setData(json.body);
    } else {
      setData({});
    }
  };

  return (
    <>
      <Container>
        <Box m={2}>
          <TextField
            className={classes.endpointTextField}
            id="input-endpoint"
            label="Endpoint"
            variant="outlined"
            value={endpoint}
            onChange={(event) => setEndpoint(event.target.value)}
          />
        </Box>
        <Grid container spacing={2} direction="row" justifyContent="center">
          <Box m={2}>
            <Button
              className={classes.button}
              onClick={() => generateUCID("Gateway")}
            >
              UGID
            </Button>
          </Box>
          <Box m={2}>
            <Button
              className={classes.button}
              onClick={() => generateUCID("Device")}
            >
              UDID
            </Button>
          </Box>
          <Box m={2}>
            <Button
              className={classes.button}
              onClick={() => generateUCID("Client")}
            >
              UCID
            </Button>
          </Box>
        </Grid>
        <br />
        <AutoComplete
          filterOptions={(x) => x}
          onPlaceSelect={handleNewPlace}
          onChange={(newInputValue) => {
            console.log(newInputValue);
          }}
        />

        <Box m={2}>
          <Button onClick={testEndpoint}>Test Endpoint</Button>
        </Box>

        {isLoading && <CircularProgress size={100} />}
        {!isLoading && !!data && (
          <>
            <Typography>{JSON.stringify(data)}</Typography>
            <Typography>{`placeID: ${selectedPlace}`}</Typography>
          </>
        )}
      </Container>
    </>
  );
};

export default TestEndpoint;
