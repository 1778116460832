/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { Container, MyButton as Button } from "./components";
// import EcgChart from "./ecgchart";
import EcgChartVis from "./ecgchartvis";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import styles from "./styles";

const baseUrl = "https://pow9rz7r6d.execute-api.us-west-2.amazonaws.com/dev";

const EcgTrace = ({ selectedClient }) => {
  const [data, setData] = useState({});
  const [configData, setConfigData] = useState({});
  const [Error, setError] = useState("");
  const [isLoading, setLoading] = useState(false);

  const classes = styles();

  const fetchEcgTracesData = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const qrs_results = await fetch(
      baseUrl + `/client/vitals/qrs?mode=data&type=dev&ucid=${selectedClient}`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: user.signInUserSession.idToken.jwtToken,
        }),
        cache: "no-cache",
      }
    );
    const json = await qrs_results.json();
    if ("Error" in json || "message" in json) {
      setData(undefined);
      setError(json?.Error || json?.message);
    } else {
      if (!!json.body[0]) {
        console.log(json.body[0].Data);
        setData(json.body[0].Data.Data);
      } else {
        setData(undefined);
        setError("");
      }
    }
  };
  const fetchEcgTracesConfig = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const config_results = await fetch(
      baseUrl + `/client/vitals/qrs?mode=config&ucid=${selectedClient}`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: user.signInUserSession.idToken.jwtToken,
        }),
        cache: "no-cache",
      }
    );
    const json = await config_results.json();
    if ("Error" in json || "message" in json) {
      setData(undefined);
      setError(json?.Error || json?.message);
    } else {
      setConfigData(json.body[0]);
      console.log(json.body[0]);
    }
  };

  useEffect(() => {
    // eslint-disable-next-line
    async function fetchData() {
      setLoading(true);
      await Promise.all([fetchEcgTracesData(), fetchEcgTracesConfig()]);
      setLoading(false);
    }
    fetchData();
  }, [selectedClient]);

  useEffect(() => {
    // fetchEcgTracesData();
    const interval = setInterval(() => fetchEcgTracesData(), 10000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  if (!!Error) {
    console.log(Error);
    return <p>{Error}</p>;
  }

  var charts = [];
  if (!isLoading) {
    if (!!data) {
      for (var k in data) {
        charts.push(
          <div className={classes.ecgBlock}>
            <Paper>
              <Grid
                container
                spacing={1}
                justifyContent="center"
                alignContent="center"
              >
                <Grid item xs={3}>
                  <Typography>{k}</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Box />
                </Grid>
                <Grid item xs={4}>
                  <Typography color="textSecondary">
                    {`Time Base: ${Math.floor(
                      data[k]["TimeBase_msec"] / 1000
                    )}S`}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography color="textSecondary">
                    {`IsValid: ${data[k]["isConnect"]}`}
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Box />
                </Grid>
                <Grid item xs={4}>
                  <Typography color="textSecondary">
                    {data[k]["Polarity"]}
                  </Typography>
                </Grid>

                <div className={classes.ecgChart}>
                  <Paper>
                    <Box marginBottom={2}>
                      <EcgChartVis
                        key={`vis-chart-${k}`}
                        traceData={data[k]}
                        configData={configData}
                      />
                    </Box>
                  </Paper>
                </div>
              </Grid>
            </Paper>
          </div>
        );
      }
    }
  }
  return (
    <>
      <link
        rel="stylesheet"
        href="https://unpkg.com/react-vis/dist/style.css"
      ></link>
      <Container>
        <Grid>
          <Box m={2}>
            <Button onClick={fetchEcgTracesData}>Fetch Ecg</Button>
          </Box>
          <Box m={2}>
            <Button onClick={fetchEcgTracesConfig}>Fetch Config</Button>
          </Box>
          {isLoading && <CircularProgress size={100} />}
          <Grid container justifyContent="center">
            {charts}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default EcgTrace;
