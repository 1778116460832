import React, { useState, useRef, useEffect } from "react";
import { Auth } from "aws-amplify";
import { MyButton as Button } from "./components";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

import styles from "./styles";

const baseUrl = "https://pow9rz7r6d.execute-api.us-west-2.amazonaws.com/dev";

const useEffectOnlyFirst = (fn, arr) => {
  const isFirst = useRef(false);

  useEffect(() => {
    if (isFirst.current) {
      return;
    } else {
      isFirst.current = true;
      return fn();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, arr);
};

const RoleOps = ({ selectedUser, onSelectRole, value }) => {
  const classes = styles();
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");

  const fetchRoles = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const endpointResp = await fetch(baseUrl + "/admin/roles", {
      method: "GET",
      headers: new Headers({
        Authorization: user.signInUserSession.idToken.jwtToken,
      }),
      cache: "no-cache",
    });
    const json = await endpointResp.json();
    setRoles(json.body[0]);
  };

  const handleSelect = (value) => {
    const newRole = value.target.value;
    console.log(newRole);
    onSelectRole(newRole);
    setSelectedRole(newRole);
  };

  const setUserRole = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const endpointResp = await fetch(
      baseUrl + `/admin/roles?uuid=${selectedUser}&role=${selectedRole}`,
      {
        method: "PUT",
        headers: new Headers({
          Authorization: user.signInUserSession.idToken.jwtToken,
        }),
        cache: "no-cache",
      }
    );
    await endpointResp.json();
  };
  useEffectOnlyFirst(fetchRoles, [roles]);
  return (
    <Box sx={{ p: 2 }}>
      <TextField
        select
        label="Role"
        onChange={handleSelect}
        className={classes.textField}
        value={value || selectedRole}
      >
        {roles.map((option, index) => {
          return (
            <MenuItem key={`dropdown-${index}-${option}`} value={option}>
              {option}
            </MenuItem>
          );
        })}
      </TextField>

      {selectedUser !== "" && <Button onClick={setUserRole}>Set Role</Button>}
    </Box>
  );
};

export default RoleOps;
