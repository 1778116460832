import React, { useState, useEffect, useCallback } from "react";
import { Auth } from "aws-amplify";
import { MyButton as Button } from "./components";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import {
  XYPlot,
  XAxis,
  YAxis,
  HorizontalGridLines,
  LineSeries,
  DiscreteColorLegend,
} from "react-vis";

const baseUrl = "https://pow9rz7r6d.execute-api.us-west-2.amazonaws.com/dev";

const TrendingVitals = ({ selectedClient }) => {
  const [data, setData] = useState({});
  const [Error, setError] = useState("");
  const [isLoading, setLoading] = useState(true);

  const axisStyle = {
    ticks: {
      fontSize: "14px",
      color: "#333",
    },
    title: {
      fontSize: "16px",
      color: "#333",
    },
  };

  const fetchVitalsDailyData = useCallback(async () => {
    const user = await Auth.currentAuthenticatedUser();
    const vitals_results = await fetch(
      baseUrl + `/client/vitals/daily?mode=data&ucid=${selectedClient}`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: user.signInUserSession.idToken.jwtToken,
        }),
        cache: "no-cache",
      }
    );
    const json = await vitals_results.json();
    if ("Error" in json || "message" in json) {
      setData(undefined);
      setError(json?.Error || json?.message);
    } else {
      if (!!json.body[0]) {
        console.log(json.body[0].Data);
        setData(json.body[0].Data);
      } else {
        setData(undefined);
        setError("");
      }
    }
  }, [selectedClient]);

  useEffect(() => {
    // eslint-disable-next-line
    async function fetchData() {
      setLoading(true);
      await fetchVitalsDailyData();
      setLoading(false);
    }
    fetchData();
  }, [fetchVitalsDailyData, selectedClient]);

  if (!!Error) {
    console.log(Error);
    return <p>{Error}</p>;
  }

  var charts = [];
  if (!isLoading) {
    if (!!data) {
      const hr_plot_data = data.hr.map((element, index) => {
        return { x: index / 120, y: element };
      });
      const hr_plot = (
        <div key="hr_plot">
          <DiscreteColorLegend
            orientation="horizontal"
            items={[
              { title: "Sitting", color: "#2ED47A" },
              { title: "Walking", color: "#C87AE5" },
              { title: "Light Activty", color: "#F5C813" },
              { title: "Laying down", color: "#109CF1" },
              { title: "Off Body", color: "#A3A3A3" },
              { title: "On Body", color: "#FF0000" },
            ]}
          />
          <XYPlot
            xType="linear"
            yPadding={30}
            width={1080}
            height={400}
            animation
          >
            <HorizontalGridLines tickTotal={5} />
            <XAxis tickTotal={10} title={"Time (Hours)"} style={axisStyle} />
            <YAxis tickTotal={5} title={"Heart Rate (bpm)"} />

            <LineSeries
              data={hr_plot_data}
              color="#FF0000"
              getNull={(d) => {
                if (d.y > 0) {
                  if (data.activity[d.x * 120] === "ONBD") {
                    return true;
                  }
                }
                return false;
              }}
            />
            <LineSeries
              data={hr_plot_data}
              color="Grey"
              getNull={(d) => {
                if (d.y > 0) {
                  if (data.activity[d.x * 120] === "Noda") {
                    return true;
                  }
                }
                return false;
              }}
            />
            <LineSeries
              data={hr_plot_data}
              color="#2ED47A"
              getNull={(d) => {
                if (d.y > 0) {
                  if (data.activity[d.x * 120] === "SITG") {
                    return true;
                  }
                }
                return false;
              }}
            />
            <LineSeries
              data={hr_plot_data}
              color="#C87AE5"
              getNull={(d) => {
                if (d.y > 0) {
                  if (data.activity[d.x * 120] === "WALK") {
                    return true;
                  }
                }
                return false;
              }}
            />
            <LineSeries
              data={hr_plot_data}
              color="#F5C813"
              getNull={(d) => {
                if (d.y > 0) {
                  if (data.activity[d.x * 120] === "LTAC") {
                    return true;
                  }
                }
                return false;
              }}
            />
            <LineSeries
              data={hr_plot_data}
              color="#109CF1"
              getNull={(d) => {
                if (d.y > 0) {
                  if (data.activity[d.x * 120] === "LAYD") {
                    return true;
                  }
                }
                return false;
              }}
            />
            <LineSeries
              data={hr_plot_data}
              color="#A3A3A3"
              getNull={(d) => {
                if (d.y > 0) {
                  if (data.activity[d.x * 120] === "OFFB") {
                    return true;
                  }
                }
                return false;
              }}
            />
          </XYPlot>
        </div>
      );
      charts.push(hr_plot);
    }
  }
  return (
    <>
      <link
        rel="stylesheet"
        href="https://unpkg.com/react-vis/dist/style.css"
      ></link>

      <Grid container justifyContent="center" alignContent="center">
        <Grid container item>
          <Box sx={{ p: 2 }}>
            <Button variant="outlined" onClick={fetchVitalsDailyData}>
              Fetch Vitals
            </Button>
          </Box>
        </Grid>
        <Paper>
          {isLoading && <CircularProgress size={100} />}
          {charts}
        </Paper>
      </Grid>
    </>
  );
};

export default TrendingVitals;
