/* eslint-disable default-case */
import React, { useState, useRef, useEffect } from "react";
import { Auth } from "aws-amplify";
import { MyButton as Button } from "./components";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Fade from "@mui/material/Fade";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "./alertcomponent";
import TimeZoneSelect from "./timezone";
import styles from "./styles";
import AutoComplete from "./autocomplete";

const baseUrl = "https://pow9rz7r6d.execute-api.us-west-2.amazonaws.com/dev";

const providerTypes = [
  "Home Care Agency",
  "Assisted Living Facility",
  "Accountable Care Organization",
  "Hospital",
  "Concierge Dr",
  "Home Health Care",
  "Skilled Nursing Facility",
  "Independent Living Facility",
  "Insurer",
  "Retailer/Distributor",
  "Telehealth",
  "Memory Care",
  "Hospice",
  "Trial",
  "Family & Friends",
  "Surgery Center",
  "Rehabilitation Group",
  "Medical Group",
  "Partner",
  "Test",
  "Other",
];

const placeToAddress = (place) => {
  if (place?.address_components === undefined) {
    return {};
  }
  const address = {
    StreetNumber: "",
    StreetName: "",
    City: "",
    State: "",
    Zip: "",
    Country: "",
    place_id: "",
  };
  place.address_components.forEach((c) => {
    switch (c.types[0]) {
      case "street_number":
        address.StreetNumber = c.long_name;
        break;
      case "route":
        address.StreetName = c.long_name;
        break;
      case "neighborhood":
      case "locality": // North Hollywood or Los Angeles?
        address.City = c.long_name;
        break;
      case "administrative_area_level_1": //  Note some countries don't have states
        address.State = c.short_name;
        break;
      case "postal_code":
        address.Zip = c.long_name;
        break;
      case "country":
        address.Country = c.long_name;
        break;
    }
  });
  address.place_id = place.place_id || "";
  return address;
};

const NewProvider = () => {
  const [isLoading, setLoading] = useState(false);

  //Form data

  const [uuid, setUUID] = useState("");
  const [provider, setProviderId] = useState(300);
  const [isIDLoading, setIsIDLoading] = useState(false);
  const [name, setName] = useState("");
  const [type, setType] = useState([]);
  const [address, setAddress] = useState({});
  const [timezone, setTimezone] = useState("");
  const [placeTextValue, setPlaceTextValue] = useState("");
  const [, setSelectedPlace] = useState({});
  const [alertState, setAlertState] = useState({
    shouldShow: false,
  });

  const classes = styles();

  const resetFields = () => {
    setName("");
    setPlaceTextValue("");
    setType([]);
    setAddress({
      ZipCode: "",
      State: "",
      City: "",
      Address1: "",
      Address2: "",
    });
    setTimezone("");
    setSelectedPlace({});
  };

  const useEffectOnlyFirst = (fn, arr) => {
    const isFirst = useRef(false);

    useEffect(() => {
      if (isFirst.current) {
        return;
      } else {
        isFirst.current = true;
        return fn();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, arr);
  };
  const handleNewProvider = async (event) => {
    setLoading(true);

    event.preventDefault();
    const user = await Auth.currentAuthenticatedUser();
    const upload = await fetch(baseUrl + "/admin/superuser/provider", {
      method: "POST",
      headers: new Headers({
        Authorization: user.signInUserSession.idToken.jwtToken,
        "Content-Type": "application/json",
      }),
      cache: "no-cache",
      body: JSON.stringify({
        provider: provider,
        uuid: uuid,
        address: address,
        timezone: !timezone ? "America/Los_Angeles" : timezone,
        name: name,
        type: type.join(","),
      }),
    });
    const json = await upload.json();
    if ("Error" in json) {
      console.log(json.Error);
      setAlertState({
        message: json.Error,
        sev: "error",
        shouldShow: true,
      });
    } else {
      console.log(json.body[0]);
      if (json.body[0].WasSuccess) {
        setAlertState({
          message: "Provider Successfully Created!",
          sev: "success",
          shouldShow: true,
        });
        fetchNextProviderID();
        fetchUUID();
      }
    }
    resetFields();
    setLoading(false);
  };

  useEffectOnlyFirst(() => {
    fetchUUID();
  }, [uuid]);

  const fetchUUID = async () => {
    setLoading(true);
    setUUID("");
    const user = await Auth.currentAuthenticatedUser();
    const uuid_result = await fetch(
      baseUrl + "/admin/superuser/uuid?type=Device",
      {
        method: "GET",
        headers: new Headers({
          Authorization: user.signInUserSession.idToken.jwtToken,
        }),
        cache: "no-cache",
      }
    );
    const json = await uuid_result.json();
    setUUID(json.body[0].uuid[0]);
    setLoading(false);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertState({ shouldShow: false });
  };

  const handleState = (event) => {
    const newState = event.target.value;
    if (event.target.value.length > 2) {
      event.preventDefault();
    } else {
      setAddress({ ...address, State: newState });
    }
  };

  const handleZip = (event) => {
    const newZip = event.target.value;
    if (event.target.value.length > 5) {
      event.preventDefault();
    } else {
      setAddress({ ...address, ZipCode: newZip });
    }
  };

  const handlePlaceSelect = async (place) => {
    const placeID = place?.place_id;
    setSelectedPlace(placeID);

    if (!!placeID && placeID !== undefined) {
      const user = await Auth.currentAuthenticatedUser();
      const endpointResp = await fetch(baseUrl + `/place?place=${placeID}`, {
        method: "GET",
        headers: new Headers({
          Authorization: user.signInUserSession.idToken.jwtToken,
        }),
        cache: "no-cache",
      });
      const json = await endpointResp.json();
      setTimezone(json.body[0].Timezone);
      const AddressComponents = placeToAddress({
        address_components: json.body[0].address_components,
      });
      setAddress({
        ...address,
        ZipCode: AddressComponents.Zip,
        State: AddressComponents.State,
        City: AddressComponents.City,
        Address1: `${AddressComponents.StreetNumber} ${AddressComponents.StreetName}`,
      });
    }
  };
  const fetchNextProviderID = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const providerResp = await fetch(baseUrl + "/admin/superuser/provider/id", {
      method: "GET",
      headers: new Headers({
        Authorization: user.signInUserSession.idToken.jwtToken,
        "Content-Type": "application/json",
      }),
      cache: "no-cache",
    });
    const json = await providerResp.json();
    setProviderId(json.body[0]);
    setIsIDLoading(false);
  };

  useEffect(() => {
    fetchNextProviderID();
  }, []);

  return (
    <>
      <Fade in={true} mountOnEnter unmountOnExit>
        <div className={classes.paperContent}>
          <Box sx={{ width: "80vw", height: 100 }}>
            {alertState.shouldShow && (
              <Grid container justifyContent="center">
                <Box sx={{ width: "25%" }}>
                  <Alert onClose={handleAlertClose} severity={alertState.sev}>
                    <Typography>{alertState.message}</Typography>
                  </Alert>
                </Box>
              </Grid>
            )}
          </Box>
          <form noValidate autoComplete="off">
            <Paper sx={{ width: "80vw" }}>
              <Grid container justifyContent="center" alignContent="center">
                <Box sx={{ p: 2 }}>
                  <Typography variant="h3">Add Provider</Typography>
                </Box>
                <Grid container item justifyContent="center">
                  <Box sx={{ p: 2 }}>
                    <TextField
                      className={classes.textField}
                      id="provider-input"
                      label="Provider ID"
                      value={provider}
                      error={provider < 0}
                      type="number"
                      onChange={(event) => setProviderId(event.target.value)}
                      required
                    />
                  </Box>
                  <Box sx={{ p: 2 }}>
                    <TextField
                      className={classes.textField}
                      id="name-input"
                      label="Provider Name"
                      value={name}
                      disabled={isIDLoading}
                      onChange={(event) => setName(event.target.value)}
                    />
                  </Box>
                  <AutoComplete
                    filterOptions={(x) => x}
                    onChange={(newInputValue) => {}}
                    onPlaceSelect={handlePlaceSelect}
                    value={placeTextValue}
                    setValue={setPlaceTextValue}
                    isIDLoading
                  />
                </Grid>
                <Grid container item justifyContent="center">
                  <Box sx={{ p: 2 }}>
                    <TextField
                      className={classes.textField}
                      id="type-input"
                      label="Type"
                      onChange={(event, value) => {
                        setType(event.target.value);
                      }}
                      value={type}
                      select
                      inputProps={{ multiple: true }}
                    >
                      {providerTypes.map((type) => (
                        <MenuItem value={type}>{type}</MenuItem>
                      ))}
                    </TextField>
                  </Box>
                  <Box sx={{ p: 2 }}>
                    <TextField
                      className={classes.uuidtextField}
                      id="uuid-input"
                      label="UUID"
                      value={uuid}
                      readOnly
                    />
                  </Box>
                </Grid>
                <Grid container item justifyContent="center">
                  <Box sx={{ p: 2 }}>
                    <TextField
                      className={classes.textField}
                      id="address-input"
                      label="Address"
                      InputLabelProps={{ shrink: true }}
                      value={address.Address1}
                      readOnly
                      onChange={(event) =>
                        setAddress({ ...address, Address1: event.target.value })
                      }
                    />
                  </Box>
                  <Box sx={{ p: 2 }}>
                    <TextField
                      className={classes.textField}
                      id="address2-input"
                      label="Address 2"
                      InputLabelProps={{ shrink: true }}
                      value={address.Address2}
                      onChange={(event) =>
                        setAddress({ ...address, Address2: event.target.value })
                      }
                    />
                  </Box>
                  <Box sx={{ p: 2 }}>
                    <TextField
                      className={classes.textField}
                      id="city-input"
                      label="City"
                      value={address.City}
                      readOnly
                      InputLabelProps={{ shrink: true }}
                      onChange={(event) =>
                        setAddress({ ...address, City: event.target.value })
                      }
                    />
                  </Box>
                  <Box sx={{ p: 2 }}>
                    <TextField
                      className={classes.smallTextField}
                      id="state-input"
                      label="State"
                      value={address.State}
                      InputLabelProps={{ shrink: true }}
                      readOnly
                      onChange={(event) => handleState(event)}
                    />
                  </Box>
                  <Box sx={{ p: 2 }}>
                    <TextField
                      className={classes.smallTextField}
                      id="Zip-input"
                      label="Zip"
                      type="number"
                      value={address.ZipCode}
                      InputLabelProps={{ shrink: true }}
                      readOnly
                      onChange={(event) => handleZip(event)}
                    />
                  </Box>
                  <TimeZoneSelect
                    value={timezone}
                    onSelect={(newTZ) => setTimezone(newTZ)}
                  />
                </Grid>

                <Box sx={{ p: 2 }}>
                  <Button onClick={(event) => handleNewProvider(event)}>
                    Submit
                  </Button>
                </Box>
                <Box sx={{ p: 2 }}>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={resetFields}
                  >
                    Clear
                  </Button>
                </Box>
              </Grid>
            </Paper>

            <Grid item justifyContent="center">
              {isLoading && (
                <Box sx={{ width: "80vw", height: 100, mt: 2 }}>
                  <CircularProgress size={100} />
                </Box>
              )}
            </Grid>
          </form>
        </div>
      </Fade>
    </>
  );
};

export default NewProvider;
