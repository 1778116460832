import blue from "@mui/material/colors/blue";
import grey from "@mui/material/colors/grey";
import green from "@mui/material/colors/green";

import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      light: blue[300],
      main: blue[500],
      dark: blue[700],
    },
    secondary: {
      light: blue[300],
      main: blue[500],
      dark: blue[700],
    },
    text: {
      main: grey[500],
    },

    success: {
      light: green[300],
      main: green[500],
      dark: green[700],
    },
  },
  shape: {
    borderRadius: 32,
  },
});

export default theme;
