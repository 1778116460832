import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import styles from "./styles";
import TableRow from "@mui/material/TableRow";
import CircularProgress from "@mui/material/CircularProgress";
const baseUrl = "https://pow9rz7r6d.execute-api.us-west-2.amazonaws.com/dev";

const ClientNotficationConfig = ({ selectedClient }) => {
  const [data, setData] = useState({});
  const [isLoading, setLoading] = useState(true);
  const classes = styles();

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      setData({});
      const user = await Auth.currentAuthenticatedUser();
      const endpointResp = await fetch(
        baseUrl + `/notification/config?ucid=${selectedClient}`,
        {
          method: "GET",
          headers: new Headers({
            Authorization: user.signInUserSession.idToken.jwtToken,
          }),
          cache: "no-cache",
        }
      );
      const json = await endpointResp.json();
      if (json.body === undefined) {
        setData(undefined);
      }
      if (!!json?.Error || !!json?.body?.Message) {
        console.log(json);
        setData(undefined);
      } else {
        console.log(json.body);
        setData(json.body[0]);
      }
      setLoading(false);
    }
    fetchData();
  }, [selectedClient]);

  if (isLoading && !!data) {
    return <p>Loading</p>;
  }
  if (!data) {
    return <p>no data</p>;
  }

  const joins = [];
  var keys = Object.keys(data);
  for (var k = 0; k < keys.length - 1; k++) {
    const key = keys[k];
    for (var j = k + 1; j < keys.length; j++) {
      const j_key = keys[j];
      if (k !== j) {
        var shouldAdd = true;
        if (JSON.stringify(data[key]) === JSON.stringify(data[j_key])) {
          for (var t in joins) {
            if (t.includes([key, j_key]) || t.includes([j_key, key])) {
              shouldAdd = false;
            }
          }
          if (shouldAdd) joins.push([key, j_key]);
        }
      }
    }
  }
  //3 inputs, one for recipient, one for notification type and one for channel type
  //notification type -> recipient -> channel type
  const objs = [];
  Object.keys(data).forEach((notificationType) => {
    Object.keys(data[notificationType]).forEach((caregiver) => {
      data[notificationType][caregiver].forEach((channelType) => {
        objs.push({
          User: notificationType,
          Notfif: caregiver,
          channel: channelType,
        });
      });
    });
  });
  console.log(objs);
  const table = (
    <TableContainer component={Paper}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Recipient</TableCell>
            <TableCell>NotificationType</TableCell>
            <TableCell>Channel</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {objs.map((row) => {
            return (
              <TableRow>
                <TableCell>{row.User}</TableCell>
                <TableCell>{row.Notfif}</TableCell>
                <TableCell>{row.channel}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );

  //reduce inputs into as little as possible
  //   console.log("inputs");
  //   console.log(inputs);
  return (
    <>
      {/* {inputs} */}
      {isLoading && <CircularProgress size={100} />}
      {!isLoading && table}
    </>
  );
};

export default ClientNotficationConfig;
