import React, { useState, useEffect, useRef } from "react";
import { Auth } from "aws-amplify";
import { MyButton as Button } from "./components";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TextField from "@mui/material/TextField";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import styles from "./styles";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

const baseUrl = "https://pow9rz7r6d.execute-api.us-west-2.amazonaws.com/dev";

const useEffectSkipFirst = (fn, arr) => {
  const isFirst = useRef(true);

  useEffect(() => {
    if (isFirst.current) {
      isFirst.current = false;
      return;
    }

    return fn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, arr);
};
const useEffectOnlyFirst = (fn, arr) => {
  const isFirst = useRef(false);

  useEffect(() => {
    if (isFirst.current) {
      return;
    } else {
      isFirst.current = true;
      return fn();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, arr);
};
const ClientDetail = ({ setBannerText }) => {
  const [data, setData] = useState(undefined);
  const mountedRef = useRef(true);
  const [isLoading, setLoading] = useState(true);
  const [clientID, setCID] = useState("f36344b9-bb02-4b27-b464-7a802c109372");
  const [sort_by_column, setSort] = useState("");
  const [sort_order, sort_descending] = useState(true);
  const classes = styles();

  useEffectSkipFirst(() => {
    return updateListWithSort().then((res) => {
      if (!mountedRef.current) return null;
    });
  }, [sort_by_column, sort_order]);

  useEffectOnlyFirst(() => {
    fetchListOfClientDetails();
  }, [data]);
  const fetchListOfClientDetails = async () => {
    setLoading(true);
    const user = await Auth.currentAuthenticatedUser();
    const endpointResp = await fetch(baseUrl + `/client/detail?mode=data`, {
      method: "GET",
      headers: new Headers({
        Authorization: user.signInUserSession.idToken.jwtToken,
      }),
      cache: "no-cache",
    });
    const json = await endpointResp.json();
    setData(json.body.Clients);
    setLoading(false);
  };

  const updateListWithSort = async () => {
    setLoading(true);
    const user = await Auth.currentAuthenticatedUser();
    const s_o = sort_order ? "sort_order=ZtoA" : undefined;
    const s_b_c = !!sort_by_column ? `sort_by_column=${sort_by_column}` : "";
    const fp = clientID !== "" ? `filter=${clientID}` : "";
    const p = [s_o, s_b_c, fp].filter((e) => !!e);
    console.log(`Params: ${p}`);
    const params = p.join("&");
    const endpointResp = await fetch(
      baseUrl + `/client/detail?mode=data&${params}`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: user.signInUserSession.idToken.jwtToken,
        }),
        cache: "no-cache",
      }
    );
    const json = await endpointResp.json();
    setData(json.body.Clients);
    console.log(json.body.Clients);
    setLoading(false);
  };

  const updateFilter = (filter) => {
    setCID(filter);
  };
  const getTableHeadFromVital = (vital, vitalString) => {
    if (sort_by_column === vital) return <>{vitalString}</>;
    return <Button onClick={() => setSort(vital)}>{vitalString}</Button>;
  };
  const reverseSort = () => {
    sort_descending(!sort_order);
  };

  const clearFilters = () => {
    sort_descending(false);
    setCID("");
    setSort("");
  };
  const getTableFromEntry = (entry) => {
    return (
      <TableRow key={entry.Index}>
        <TableCell>{entry.Name}</TableCell>
        <TableCell>{entry?.Data?.HeartRate.String || "-"}</TableCell>
        <TableCell>{entry?.Data?.SkinTempF.String || "-"}</TableCell>
        <TableCell>{entry?.Data?.RespRate.String || "-"}</TableCell>
        <TableCell>{entry?.Data?.BloodPres?.String || "-"}</TableCell>
        <TableCell>{entry?.Data?.SpO2?.String || "-"}</TableCell>
        <TableCell>{entry?.Modified_msec || "-"}</TableCell>
      </TableRow>
    );
  };
  return (
    <div className={classes.root}>
      <Grid container justifyContent="center">
        <Grid item>{isLoading && <CircularProgress size={100} />}</Grid>
        {!isLoading && (
          <Box m={2}>
            <Paper className={classes.paper}>
              <Grid item xs={12}>
                {!data && (
                  <Button variant="outlined" onClick={fetchListOfClientDetails}>
                    Fetch Client Details
                  </Button>
                )}
              </Grid>
              {!!data && (
                <>
                  <Grid item>
                    <Box m={1}>
                      <Button onClick={clearFilters}>
                        Clear Filters and Sorts
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Button onClick={reverseSort}>Toggle sort</Button>
                  </Grid>
                </>
              )}
              <Grid item>
                {!isLoading && (
                  <form className={classes.root} noValidate autoComplete="off">
                    <Box m={1}>
                      <TextField
                        className={classes.textField}
                        id="filterClient"
                        label="Filter"
                        variant="outlined"
                        value={clientID}
                        onChange={(event) => updateFilter(event.target.value)}
                      />
                    </Box>
                  </form>
                )}
              </Grid>
            </Paper>
          </Box>
        )}
        {!isLoading && !!data && (
          <>
            <TableContainer component={Paper}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      {getTableHeadFromVital("Name", "Name")}
                    </TableCell>
                    {/* <TableCell>
                      {getTableHeadFromVital("AlertCount", "Alert Count")}
                    </TableCell> */}
                    <TableCell>
                      {getTableHeadFromVital("HeartRate", "Heart Rate")}
                    </TableCell>
                    <TableCell>
                      {getTableHeadFromVital("SkinTempF", "Skin Temperature")}
                    </TableCell>
                    <TableCell>
                      {getTableHeadFromVital("RespRate", "Respiration")}
                    </TableCell>
                    <TableCell>
                      {getTableHeadFromVital("BloodPres", "Blood Pressure")}
                    </TableCell>
                    <TableCell>
                      {getTableHeadFromVital("SpO2", "SpO2")}
                    </TableCell>
                    <TableCell>{"Modified_msec"}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{data.map((e) => getTableFromEntry(e))}</TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </Grid>
    </div>
  );
};

export default ClientDetail;
