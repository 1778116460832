import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { getTimeZones } from "@vvo/tzdb";

const TimeZoneSelect = ({ onSelect, value }) => {
  const handleSelect = (value) => {
    console.log(value);
    onSelect(value?.value);
  };
  return (
    <Box sx={{ p: 2 }}>
      <Autocomplete
        disablePortal
        id="timezoneSelect"
        options={getTimeZones().map((item) => {
          return {
            label: `${item.alternativeName} (${item.mainCities[0]})`,
            value: item.name,
          };
        })}
        isOptionEqualToValue={(option, value) => {
          return option.value === value.value;
        }}
        value={value}
        onChange={(event, newValue) => {
          handleSelect(newValue);
        }}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="Time Zone" />}
      />
    </Box>
  );
};

export default TimeZoneSelect;
