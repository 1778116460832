import React, { useState, useRef, useEffect } from "react";
import { Auth } from "aws-amplify";
import { MyButton as Button } from "./components";
import CircularProgress from "@mui/material/CircularProgress";
import Input from "@mui/material/Input";

import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import InputLabel from "@mui/material/InputLabel";

import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import Fade from "@mui/material/Fade";
import Alert from "./alertcomponent";

import styles from "./styles";

const baseUrl = "https://pow9rz7r6d.execute-api.us-west-2.amazonaws.com/dev";

const thresholdModes = ["Default", "Custom"];
const alertGroupTypes = [
  "Temperature",
  "HeartRate",
  "BloodPres",
  "OffBody",
  "SpO2",
  "Connection",
  "Ecg",
  "Weight",
  "Provider",
  "Gateway",
  "Battery",
  "Fall",
  "Respiration",
  "Patch",
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useEffectOnlyFirst = (fn, arr) => {
  const isFirst = useRef(false);

  useEffect(() => {
    if (isFirst.current) {
      return;
    } else {
      isFirst.current = true;
      return fn();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, arr);
};

const AlertConfig = ({ selectedClient }) => {
  const [data, setData] = useState({});
  const [isLoading, setLoading] = useState(false);

  const [alertState, setAlertState] = useState({ shouldShow: false });

  const [HeartRateThreshold, setHeartRateThreshold] = useState({
    upper: 0.0,
    lower: 0.0,
  });
  const [PulseOxThreshold, setPulseOxThreshold] = useState(0.0);
  const [RespirationThreshold, setRespirationThreshold] = useState({
    upper: 0.0,
    lower: 0.0,
  });
  const [TemperatureThreshold, setTemperatureThreshold] = useState(0.0);
  const [
    BloodPressureSystemThreshold,
    setBloodPressureSystemThreshold,
  ] = useState({
    upper: 0.0,
    lower: 0.0,
  });
  const [ThreshholdMode, setThreshholdMode] = useState("Default");
  const [enabledList, setEnabledList] = useState([]);
  const [notes, setNotes] = useState("");

  const classes = styles();

  const fetchListOfClients = async () => {
    setLoading(true);
    const user = await Auth.currentAuthenticatedUser();
    const endpointResp = await fetch(baseUrl + "/client", {
      method: "GET",
      headers: new Headers({
        Authorization: user.signInUserSession.idToken.jwtToken,
      }),
      cache: "no-cache",
    });
    const json = await endpointResp.json();
    for (var i in json.body) {
      const client = json.body[i];
      if (client?.UCID === selectedClient) {
        console.log("Found client");
        console.log(client.AlertConfig);
        setData(client);
        setHeartRateThreshold({
          lower: client?.AlertConfig?.HeartRateThresholdLower,
          upper: client?.AlertConfig?.HeartRateThresholdUpper,
        });
        setBloodPressureSystemThreshold({
          lower: client?.AlertConfig?.BloodPresSystThresholdLower_mmHg,
          upper: client?.AlertConfig?.BloodPresSystThresholdUpper_mmHg,
        });
        setPulseOxThreshold(client?.AlertConfig?.PulseOximeterThresholdLower_F);
        setTemperatureThreshold(
          client?.AlertConfig?.TemperatureThresholdUpper_F
        );
        setRespirationThreshold({
          lower: client?.AlertConfig?.RespirationRateThresholdLower,
          upper: client?.AlertConfig?.RespirationRateThresholdUpper,
        });
        var enabledList = [];
        for (var k in client.AlertConfig.IsEnabledMap) {
          if (client.AlertConfig.IsEnabledMap[k]) {
            enabledList.push(k);
          }
        }
        setEnabledList(enabledList);
        setNotes(client.AlertConfig.Modified_Comment);
        break;
      }
    }
    setLoading(false);
  };

  const saveAlertConfig = async () => {
    setLoading(true);
    const user = await Auth.currentAuthenticatedUser();
    console.log(user.attributes.sub);
    var final_map = {};
    for (var i in alertGroupTypes) {
      final_map[alertGroupTypes[i]] = enabledList.includes(alertGroupTypes[i]);
    }
    const payload = {
      AlertConfig: {
        HeartRateThresholdUpper: HeartRateThreshold.upper,
        HeartRateThresholdLower: HeartRateThreshold.lower,
        BloodPresSystThresholdLower_mmHg: BloodPressureSystemThreshold.lower,
        BloodPresSystThresholdUpper_mmHg: BloodPressureSystemThreshold.upper,
        TemperatureThresholdUpper_F: TemperatureThreshold,
        RespirationRateThresholdLower: RespirationThreshold.lower,
        RespirationRateThresholdUpper: RespirationThreshold.upper,
        PulseOximeterThresholdLower_F: PulseOxThreshold,
        Modified_UUID: user.attributes.sub,
        IsEnabledMap: final_map,
        Modified_Comment: notes,
      },
    };
    const alert_fetch = await fetch(
      baseUrl + `/client/profile?UCID=${selectedClient}`,
      {
        method: "PUT",
        headers: new Headers({
          Authorization: user.signInUserSession.idToken.jwtToken,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify(payload),
        cache: "no-cache",
      }
    );
    const json = await alert_fetch.json();
    if (json.body[0].WasSuccess) {
      setAlertState({
        message: "Updated Alert Config Succussfully",
        sev: "success",
        shouldShow: true,
      });
    }
    setLoading(false);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertState({ shouldShow: false });
  };
  const handleChipChange = (event) => {
    console.log("Changing list");
    console.log(event.target.value);
    setEnabledList(event.target.value);
  };

  useEffectOnlyFirst(fetchListOfClients, [data]);
  return (
    <>
      <Fade in={true} mountOnEnter unmountOnExit>
        <div className={classes.paperContent}>
          <Box sx={{ width: "80vw", height: "50px", p: 2 }}>
            {alertState.shouldShow && (
              <Grid container justifyContent="center">
                <Box sx={{ width: "25%" }}>
                  <Alert onClose={handleAlertClose} severity={alertState.sev}>
                    <Typography>{alertState.message}</Typography>
                  </Alert>
                </Box>
              </Grid>
            )}
          </Box>
          <form noValidate autoComplete="off">
            <Paper sx={{ width: "80vw" }} elevation={5}>
              <Grid container justifyContent="center" alignContent="center">
                <Box sx={{ p: 2 }}>
                  <Typography variant="h3">Alert Config</Typography>
                </Box>
                <Grid container item justifyContent="center">
                  <Box m={2}>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="dropdown-select-mode">
                        Threshold Mode
                      </InputLabel>

                      <Select
                        className={classes.textField}
                        id="dropdown-select-mode"
                        label="Threshold Mode"
                        value={`${ThreshholdMode}`}
                        onChange={(event) =>
                          setThreshholdMode(event.target.value)
                        }
                        required
                      >
                        {thresholdModes.map((option, index) => {
                          return (
                            <MenuItem
                              key={`dropdown-${index}-${option}`}
                              value={option}
                            >
                              {option}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid
                  container
                  item
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid xs={2}>
                    <Typography>Heart Rate:</Typography>
                  </Grid>
                  <Box m={2}>
                    <TextField
                      className={classes.textField}
                      id="hrMax-input"
                      label="Max"
                      value={HeartRateThreshold.upper}
                      onChange={(event) =>
                        setHeartRateThreshold({ upper: event.target.value })
                      }
                    />
                  </Box>
                  <Box m={2}>
                    <TextField
                      className={classes.textField}
                      id="hrmin-input"
                      label="Min"
                      value={HeartRateThreshold.lower}
                      onChange={(event) =>
                        setHeartRateThreshold({ lower: event.target.value })
                      }
                    />
                  </Box>
                </Grid>
                <Grid
                  container
                  item
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid xs={2}>
                    <Typography>Blood Preassure System Threshold:</Typography>
                  </Grid>
                  <Box m={2}>
                    <TextField
                      className={classes.textField}
                      id="bpMax-input"
                      label="Max"
                      value={BloodPressureSystemThreshold.upper}
                      onChange={(event) =>
                        setBloodPressureSystemThreshold({
                          upper: event.target.value,
                        })
                      }
                    />
                  </Box>
                  <Box m={2}>
                    <TextField
                      className={classes.textField}
                      id="bpMin-input"
                      label="Min"
                      value={BloodPressureSystemThreshold.lower}
                      onChange={(event) =>
                        setBloodPressureSystemThreshold({
                          lower: event.target.value,
                        })
                      }
                    />
                  </Box>
                </Grid>
                <Grid
                  container
                  item
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid xs={2}>
                    <Typography>Respiration Threshold:</Typography>
                  </Grid>
                  <Box m={2}>
                    <TextField
                      className={classes.textField}
                      id="spo02Max-input"
                      label="Max"
                      value={RespirationThreshold.upper}
                      onChange={(event) =>
                        setRespirationThreshold({ upper: event.target.value })
                      }
                    />
                  </Box>
                  <Box m={2}>
                    <TextField
                      className={classes.textField}
                      id="spo02Min-input"
                      label="Min"
                      value={RespirationThreshold.lower}
                      onChange={(event) =>
                        setRespirationThreshold({ lower: event.target.value })
                      }
                    />
                  </Box>
                </Grid>
                <Grid
                  container
                  item
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid xs={2}>
                    <Typography>PulseOx Threshold:</Typography>
                  </Grid>
                  <Box m={2}>
                    <TextField
                      className={classes.textField}
                      id="spo02Max-input"
                      label="Max"
                      value={PulseOxThreshold}
                      onChange={(event) =>
                        setPulseOxThreshold(event.target.value)
                      }
                    />
                  </Box>
                </Grid>
                <Grid
                  container
                  item
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid xs={2}>
                    <Typography>Temperature Threshold:</Typography>
                  </Grid>
                  <Box m={2}>
                    <TextField
                      className={classes.textField}
                      id="tempMax-input"
                      label="Max"
                      value={TemperatureThreshold}
                      onChange={(event) =>
                        setTemperatureThreshold(event.target.value)
                      }
                    />
                  </Box>
                </Grid>
                <Grid
                  container
                  item
                  alignItems="center"
                  justifyContent="center"
                >
                  <FormControl className={classes.formControl}>
                    <InputLabel id="grouptype-chip-label">
                      IsEnabledMap
                    </InputLabel>
                    <Select
                      labelId="grouptype-chip-label"
                      id="grouptype-chip"
                      multiple
                      value={enabledList}
                      onChange={handleChipChange}
                      input={<Input id="grouptype-chip-select" />}
                      renderValue={(selected) => (
                        <div>
                          {selected.map((value) => (
                            <Chip
                              key={value}
                              label={value}
                              className={classes.chip}
                            />
                          ))}
                        </div>
                      )}
                      MenuProps={MenuProps}
                    >
                      {alertGroupTypes.map((name) => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  container
                  item
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid xs={2}>
                    <Typography>Change comments:</Typography>
                  </Grid>
                  <Box m={2}>
                    <TextField
                      className={classes.textField}
                      multiline
                      id="notes-input"
                      label="Notes"
                      value={notes}
                      onChange={(event) => setNotes(event.target.value)}
                    />
                  </Box>
                </Grid>
                <Box m={2}>
                  <Button onClick={(event) => saveAlertConfig(event)}>
                    Submit
                  </Button>
                </Box>
                <Box m={2}>
                  <Button variant="outlined" onClick={() => {}}>
                    Cancel
                  </Button>
                </Box>
              </Grid>
              <Grid item>{isLoading && <CircularProgress size={100} />}</Grid>
            </Paper>
          </form>
        </div>
      </Fade>
    </>
  );
};

export default AlertConfig;
