/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from "react";
import { Auth } from "aws-amplify";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import AddressAutoComplete from "./autocomplete";
import Fade from "@mui/material/Fade";
import CircularProgress from "@mui/material/CircularProgress";

import styles from "./styles";

const baseUrl = "https://pow9rz7r6d.execute-api.us-west-2.amazonaws.com/dev";

const NewClient = ({ cancel, providers }) => {
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [uuid, setUUID] = useState("");
  const [clientID, setClientID] = useState("");
  const [gender, setGender] = useState("Unspecified");
  const [timezone, setTimezone] = useState("US/Pacific");
  const [selectedProvider, setSelectedProvider] = useState("");

  const classes = styles();

  const useEffectOnlyFirst = (fn, arr) => {
    const isFirst = useRef(false);

    useEffect(() => {
      if (isFirst.current) {
        return;
      } else {
        isFirst.current = true;
        return fn();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, arr);
  };
  const handleNewUser = async (event) => {
    setLoading(true);
    setData({});
    event.preventDefault();
    const user = await Auth.currentAuthenticatedUser();
    const upload = await fetch(baseUrl + "/client/photo", {
      method: "POST",
      headers: new Headers({
        Authorization: user.signInUserSession.idToken.jwtToken,
        "Content-Type": "application/json",
      }),
      cache: "no-cache",
      body: JSON.stringify({
        somedict: "Ely",
      }),
    });
    const json = await upload.json();
    setData(json.body[0]);
    setLoading(false);
  };

  useEffectOnlyFirst(() => {
    fetchUUID();
  }, [uuid]);
  useEffectOnlyFirst(() => {
    fetchClientID();
  }, [clientID]);

  const fetchUUID = async () => {
    setLoading(true);
    setUUID("");
    const user = await Auth.currentAuthenticatedUser();
    const uuid_result = await fetch(
      baseUrl + "/admin/superuser/uuid?type=Client",
      {
        method: "GET",
        headers: new Headers({
          Authorization: user.signInUserSession.idToken.jwtToken,
        }),
        cache: "no-cache",
      }
    );
    const json = await uuid_result.json();
    setUUID(json.body[0].uuid);
    setLoading(false);
  };
  const fetchClientID = async () => {
    setLoading(true);
    setClientID("");
    const user = await Auth.currentAuthenticatedUser();
    const client_id = await fetch(baseUrl + "/client/next-id", {
      method: "GET",
      headers: new Headers({
        Authorization: user.signInUserSession.idToken.jwtToken,
      }),
      cache: "no-cache",
    });
    const json = await client_id.json();
    setClientID(json.body[0].cid);
    setLoading(false);
  };

  return (
    <Fade in={true} mountOnEnter unmountOnExit>
      <div className={classes.paperContent}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <Box sx={{ p: 2 }}>
              <Typography variant="h3">Coming soon</Typography>
            </Box>
          </Grid>
          <Box sx={{ p: 2 }}>
            <CircularProgress size={100} />
          </Box>
        </Grid>
      </div>
    </Fade>
  );

  //   return (
  //     <Fade in={true} mountOnEnter unmountOnExit>
  //       <div className={classes.paperContent}>
  //         <form noValidate autoComplete="off">
  //           {/* <Paper className={classes.paperContent}> */}
  //           <Paper sx={{ width: "80vw" }}>
  //             <Grid container justifyContent="center" alignContent="center">
  //               <Typography variant="h3">Add Client</Typography>
  //               <Grid container item justifyContent="center">
  //                 <Box m={2}>
  //                   <TextField
  //                     className={classes.textField}
  //                     id="first-input"
  //                     label="First Name"
  //                     // value={selectedClient}
  //                     // onChange={(event) => setSelectedClient(event.target.value)}
  //                   />
  //                 </Box>

  //                 <Box m={2}>
  //                   <TextField
  //                     className={classes.textField}
  //                     id="Last-input"
  //                     label="Last Name"
  //                     // value={selectedClient}
  //                     // onChange={(event) => setSelectedClient(event.target.value)}
  //                   />
  //                 </Box>

  //                 <Box m={2}>
  //                   <TextField
  //                     className={classes.textField}
  //                     id="birthdate-input"
  //                     label="Birthdate"
  //                     type="date"
  //                     defaultValue="1970-01-01"
  //                     InputLabelProps={{
  //                       shrink: true,
  //                     }}
  //                     // value={selectedClient}
  //                     // onChange={(event) => setSelectedClient(event.target.value)}
  //                   />
  //                 </Box>
  //                 <Box m={2}>
  //                   <AddressAutoComplete
  //                     id="Address-input"
  //                     onChange={(event) => setAddress(event.target.value)}
  //                   />
  //                 </Box>
  //                 <br />
  //                 <Box m={2}>
  //                   <TextField
  //                     className={classes.textField}
  //                     id="uuid-input"
  //                     label="UUID"
  //                     value={uuid}
  //                     readOnly
  //                     // onChange={(event) => setSelectedClient(event.target.value)}
  //                   />
  //                 </Box>

  //                 <Box m={2}>
  //                   <FormControl className={classes.formControl}>
  //                     <InputLabel id="dropdown-select-gender">
  //                       {"Gender"}
  //                     </InputLabel>

  //                     <Select
  //                       className={classes.textField}
  //                       labelid="dropdown-select-gender"
  //                       id="dropdown-select-gender"
  //                       value={`${gender}`}
  //                       onChange={(event) => setGender(event.target.value)}
  //                     >
  //                       {["Male", "Female", "Unspecified", "Other"].map(
  //                         (option, index) => {
  //                           return (
  //                             <MenuItem
  //                               key={`dropdown-${index}-${option}`}
  //                               value={option}
  //                             >
  //                               {option}
  //                             </MenuItem>
  //                           );
  //                         }
  //                       )}
  //                     </Select>
  //                   </FormControl>
  //                 </Box>
  //                 <Box m={2}>
  //                   <TextField
  //                     className={classes.textField}
  //                     id="timezone-input"
  //                     label="TimeZone"
  //                     value={timezone}
  //                     onChange={(event) => setTimezone(event.target.value)}
  //                   />
  //                 </Box>
  //                 <Box m={2}>
  //                   <TextField
  //                     className={classes.textField}
  //                     id="notes-input"
  //                     label="notes"
  //                     multiline
  //                     // onChange={(event) => setSelectedClient(event.target.value)}
  //                   />
  //                 </Box>
  //                 <Box m={2}>
  //                   <TextField
  //                     className={classes.textField}
  //                     id="client-id-input"
  //                     label="Client ID"
  //                     value={clientID}
  //                     readOnly
  //                     // onChange={(event) => setSelectedClient(event.target.value)}
  //                   />
  //                 </Box>
  //                 <Box m={2}>
  //                   <FormControl className={classes.formControl}>
  //                     <InputLabel id="dropdown-select-provider">
  //                       {"Provider"}
  //                     </InputLabel>

  //                     <Select
  //                       className={classes.textField}
  //                       labelid="dropdown-select-provider"
  //                       id="dropdown-select-provider"
  //                       value={`${selectedProvider}`}
  //                       onChange={(event) =>
  //                         setSelectedProvider(event.target.value)
  //                       }
  //                     >
  //                       {providers.map((option, index) => {
  //                         return (
  //                           <MenuItem
  //                             key={`dropdown-${index}-${option.UPID}`}
  //                             value={option.UPID}
  //                           >
  //                             {`${option.Name} (${option.ProvID})`}
  //                           </MenuItem>
  //                         );
  //                       })}
  //                     </Select>
  //                   </FormControl>
  //                 </Box>
  //               </Grid>
  //               <Box m={2}>
  //                 <Button onClick={(event) => handleNewUser(event)}>
  //                   Submit
  //                 </Button>
  //               </Box>
  //               <Box m={2}>
  //                 <Button variant="outlined" onClick={cancel}>
  //                   Cancel
  //                 </Button>
  //               </Box>
  //             </Grid>
  //           </Paper>
  //         </form>
  //       </div>
  //     </Fade>
  //   );
};

export default NewClient;
