import React, { useState, useRef } from "react";
import { Auth } from "aws-amplify";
import { Container, MyButton as Button } from "./components";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import InputBase from "@mui/material/InputBase";
import CircularProgress from "@mui/material/CircularProgress";

import styles from "./styles";

const baseUrl = "https://pow9rz7r6d.execute-api.us-west-2.amazonaws.com/dev";
const PhotoOps = ({ selectedClient, setSelectedClient }) => {
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const fileInput = useRef(null);
  const classes = styles();

  const handleImageUpload = async (event, ucid) => {
    setLoading(true);
    setData({});
    event.preventDefault();
    const user = await Auth.currentAuthenticatedUser();
    const reader = new FileReader();
    console.log(fileInput.current.files);
    reader.readAsDataURL(fileInput.current.files[0]);
    reader.addEventListener("load", async () => {
      const data_parts = reader.result.split(",");
      console.log(data_parts[0]);
      const payload = {
        UCID: ucid,
        file: data_parts[1],
      };
      const upload = await fetch(baseUrl + "/client/photo", {
        method: "POST",
        headers: new Headers({
          Authorization: user.signInUserSession.idToken.jwtToken,
          "Content-Type": "application/json",
        }),
        cache: "no-cache",
        body: JSON.stringify(payload),
      });
      const json = await upload.json();
      setData(json.body[0]);
    });
    setLoading(false);
  };

  return (
    <>
      <Container className={classes.paperContent}>
        <div className={classes.paper}>
          <Grid justifyContent="center">
            <Paper>
              <form className={classes.root} noValidate autoComplete="off">
                <Box m={2}>
                  <Grid item xs={12}>
                    <TextField
                      className={classes.textField}
                      id="uploadClient"
                      label="Client ID"
                      variant="outlined"
                      fullwidth="true"
                      value={selectedClient}
                      onChange={(event) =>
                        setSelectedClient(event.target.value)
                      }
                    />
                  </Grid>
                </Box>
              </form>
              <Grid item xs={12}>
                <Typography> Upload Profile Picture</Typography>
              </Grid>
              <Grid item>
                <Box m={2}>
                  <InputBase
                    accept="image/*"
                    className={classes.fileInput}
                    id="contained-button-file"
                    type="file"
                    inputRef={fileInput}
                  />
                  <label htmlFor="contained-button-file">
                    <Button component="span">Upload</Button>
                  </label>
                </Box>
              </Grid>
              <Grid item>
                <Box m={2}>
                  <Button
                    onClick={(event) =>
                      handleImageUpload(event, selectedClient)
                    }
                  >
                    Submit
                  </Button>
                </Box>
              </Grid>
              {isLoading && <CircularProgress size={50} />}
              <br />
              {!isLoading && (
                <Typography>{`WasSuccess: ${data.WasSuccess}`}</Typography>
              )}
            </Paper>
          </Grid>
        </div>
      </Container>
    </>
  );
};

export default PhotoOps;
