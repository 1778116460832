import React, { useState, useRef, useEffect, useCallback } from "react";
import { Auth } from "aws-amplify";
import { MyButton as Button } from "./components";

import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import MenuItem from "@mui/material/MenuItem";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import { green, red } from "@mui/material/colors";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Skeleton from "@mui/material/Skeleton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import LoadingButton from "@mui/lab/LoadingButton";

import Alert from "./alertcomponent";

import NewPatch from "./newpatch";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";

import styles from "./styles";

const patches = Array.from(Array(15).keys());

const baseUrl = "https://pow9rz7r6d.execute-api.us-west-2.amazonaws.com/dev";

const conformingstatuses = [
  "NotDetermined",
  "Conforming",
  "NonConforming",
  "Scrapped",
];

const useEffectOnlyFirst = (fn, arr) => {
  const isFirst = useRef(false);

  useEffect(() => {
    if (isFirst.current) {
      return;
    } else {
      isFirst.current = true;
      return fn();
    }
    // eslint-disable-next-line
  }, [arr]);
};

const blankPSN = { P: "", L: "", SN: "" };
const PatchList = () => {
  const [providers, setProviders] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState("");
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [showNewPatch, setShowNewPatch] = useState(false);
  const [alertState, setAlertState] = useState({ shouldShow: false });

  const [selectedDevice, setSelectedDevice] = useState("");
  // Conforming device stuff
  const [showConformModal, setShowConformModal] = useState(false);
  const [deviceStatus, setDeviceStatus] = useState(conformingstatuses[0]);
  const [conformnotes, setConformNotes] = useState("");
  const [conformloading, setConfromLoading] = useState(false);

  // Provider device states
  const [showProviderModal, setShowProviderModal] = useState(false);
  const [providerloading, setProviderLoading] = useState(false);
  const [newProvider, setNewProvider] = useState(0);

  // PSN device stuff
  const [showPsnModal, setShowPsnModal] = useState(false);
  const [isPsnLoading, setPsnLoading] = useState(false);
  const [PLSn, setPLSn] = useState({ ...blankPSN });

  const [editMode, setEditMode] = useState(false);

  const classes = styles();

  const fetchListOfPatches = useCallback(
    async (shouldLoad) => {
      if (shouldLoad) {
        setLoading(true);
      }
      const user = await Auth.currentAuthenticatedUser();
      const endpointResp = await fetch(
        baseUrl + `/inventory/patch?upid=${selectedProvider}&detailed=True`,
        {
          method: "GET",
          headers: new Headers({
            Authorization: user.signInUserSession.idToken.jwtToken,
          }),
          cache: "no-cache",
        }
      );
      const json = await endpointResp.json();
      setData(json.body);
      if (shouldLoad) setLoading(false);
    },
    [selectedProvider]
  );

  const fetchListOfProviders = async () => {
    setLoading(true);
    const user = await Auth.currentAuthenticatedUser();
    const endpointResp = await fetch(baseUrl + "/provider", {
      method: "GET",
      headers: new Headers({
        Authorization: user.signInUserSession.idToken.jwtToken,
      }),
      cache: "no-cache",
    });
    const json = await endpointResp.json();

    let providers = json.body;
    providers = providers.sort((a, b) => a.ProvID - b.ProvID);
    setProviders(providers);
    setSelectedProvider(providers[0].UPID);
    console.log(json.body[0].UPID);

    setLoading(false);
  };

  const updateConform = async (event) => {
    setConfromLoading(true);
    event.preventDefault();
    const user = await Auth.currentAuthenticatedUser();
    const endpointResp = await fetch(
      baseUrl + `/admin/superuser/patch?deviceid=${selectedDevice}`,
      {
        method: "PUT",
        headers: new Headers({
          Authorization: user.signInUserSession.idToken.jwtToken,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          notes: conformnotes,
          status: deviceStatus,
        }),
        cache: "no-cache",
      }
    );

    const json = await endpointResp.json();
    if ("Error" in json) {
      console.log(json.Error);
      setAlertState({
        message: json.Error,
        sev: "error",
        shouldShow: true,
      });
    } else {
      console.log(json.body[0]);
      if (json.body[0].WasSuccess) {
        setAlertState({
          message: "Conform Status Updated!",
          sev: "success",
          shouldShow: true,
        });
      }
      setConfromLoading(false);
      handleConformModalClose();
    }

    fetchListOfPatches(false);
  };
  const updateIsConform = async (event, udid) => {
    event.preventDefault();
    const user = await Auth.currentAuthenticatedUser();
    const endpointResp = await fetch(
      baseUrl + `/admin/superuser/patch?deviceid=${udid}`,
      {
        method: "PUT",
        headers: new Headers({
          Authorization: user.signInUserSession.idToken.jwtToken,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          isconforming: !event.target.checked,
        }),
        cache: "no-cache",
      }
    );
    await endpointResp.json();
    fetchListOfPatches(false);
  };
  const updateDevicePSN = async (udid) => {
    const user = await Auth.currentAuthenticatedUser();
    const endpointResp = await fetch(
      baseUrl + `/admin/superuser/patch?deviceid=${udid}`,
      {
        method: "PUT",
        headers: new Headers({
          Authorization: user.signInUserSession.idToken.jwtToken,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          psn: PLSn,
        }),
        cache: "no-cache",
      }
    );
    await endpointResp.json();
    fetchListOfPatches(false);
  };

  const updateDeviceProvider = async (udid) => {
    setProviderLoading(true);
    const user = await Auth.currentAuthenticatedUser();
    const endpointResp = await fetch(
      baseUrl + `/admin/superuser/patch?deviceid=${udid}`,
      {
        method: "PUT",
        headers: new Headers({
          Authorization: user.signInUserSession.idToken.jwtToken,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          newprovider: newProvider,
        }),
        cache: "no-cache",
      }
    );
    const json = await endpointResp.json();
    if ("Error" in json) {
      console.log(json.Error);
      setAlertState({
        message: json.Error,
        sev: "error",
        shouldShow: true,
      });
    } else {
      console.log(json.body[0]);
      if (json.body[0].WasSuccess) {
        setAlertState({
          message: "Provider Updated!",
          sev: "success",
          shouldShow: true,
        });
      } else {
        setAlertState({
          message: json.body[0].Message,
          sev: "warning",
          shouldShow: true,
        });
      }
    }
    fetchListOfPatches(false);
    setProviderLoading(false);
    handleProviderModalClose();
  };

  const handleProviderSelect = async (value) => {
    setEditMode(false);
    setSelectedProvider(value);
  };

  useEffect(() => {
    if (!selectedProvider.current) {
      setData([]);
    }
    fetchListOfPatches(true);
    return function cleanup() {
      setData([]);
    };
  }, [fetchListOfPatches, selectedProvider]);

  const handleConformModal = (device) => {
    setSelectedDevice(device);
    setShowConformModal(true);
  };
  const handleProviderModal = (device) => {
    setSelectedDevice(device);
    setShowProviderModal(true);
  };
  const handlePsnModal = (device) => {
    setSelectedDevice(device);
    const selectedDevice = data.find((s) => s.UDID === device);
    const psn = selectedDevice.ProdSerNum;
    console.log(psn);
    if (psn && psn.includes(":")) {
      const splitPsn = psn?.split(":");
      if (splitPsn.length === 3) {
        setPLSn({ P: splitPsn[0], L: splitPsn[1], SN: splitPsn[2] });
      }
    }
    setShowPsnModal(true);
  };

  const handleConformModalClose = () => {
    setSelectedDevice("");
    setConformNotes("");
    setDeviceStatus(conformingstatuses[0]);
    setConfromLoading(false);
    setShowConformModal(false);
  };
  const handleProviderModalClose = () => {
    setSelectedDevice("");
    setNewProvider(0);
    setProviderLoading(false);
    setShowProviderModal(false);
  };

  const handlePsnModalClose = () => {
    setSelectedDevice("");
    setNewProvider(0);
    setPLSn({ ...blankPSN });
    setPsnLoading(false);
    setShowPsnModal(false);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertState({ shouldShow: false });
  };
  const getSkeletonFromEntry = (rowNum) => {
    return (
      <TableRow key={`skeleton-row-${rowNum}`}>
        <TableCell align="left" width={150}>
          <Skeleton />
        </TableCell>
        <TableCell align="right" width={80}>
          <Skeleton />
        </TableCell>
        <TableCell align="right" width={150}>
          <Skeleton />
        </TableCell>
        <TableCell align="right" width={200}>
          <Skeleton />
        </TableCell>
        <TableCell align="right" width={300}>
          <Skeleton />
        </TableCell>
        <TableCell align="right" width={165}>
          <Skeleton />
        </TableCell>
        <TableCell align="center" width={50}>
          <Grid container justifyContent="center" alignItems="center">
            <Skeleton variant="circular" width={16} height={16} />
          </Grid>
        </TableCell>
        <TableCell align="right" width={120}>
          <Skeleton />
        </TableCell>
      </TableRow>
    );
  };
  const getTableFromEntry = (entry) => {
    return (
      <TableRow key={`sensor-row-${entry.Index}`}>
        <TableCell width={150} align="left">
          {entry.DeviceID}
        </TableCell>
        {editMode && (
          <TableCell width={100} align="right">
            <Button
              size={"small"}
              variant="text"
              onClick={() => handleProviderModal(entry.UDID)}
            >
              update
            </Button>
          </TableCell>
        )}
        {!editMode && <TableCell align="right">{entry.ProvID}</TableCell>}
        <TableCell align="right">{entry.ClientID}</TableCell>
        {!editMode && (
          <TableCell align="right" width={200}>
            {entry?.ProdSerNum}
          </TableCell>
        )}
        {editMode && (
          <TableCell align="right" width={200}>
            <Button
              size={"small"}
              variant="text"
              onClick={() => handlePsnModal(entry.UDID)}
            >
              update
            </Button>
          </TableCell>
        )}

        <TableCell align="right">{entry.UDID}</TableCell>
        <TableCell align="right">{entry.DeviceType}</TableCell>
        <TableCell align="center">
          {editMode && (
            <Switch
              size="small"
              onChange={(event) => {
                // console.log(event.target.checked);
                updateIsConform(event, entry.UDID);
              }}
              checked={entry.IsConforming}
              color="primary"
            />
          )}
          {!editMode && (
            <FiberManualRecordIcon
              style={{ color: entry.IsConforming ? green[500] : red[500] }}
            />
          )}
        </TableCell>
        <TableCell align="right">{entry?.Quality?.ConformingStatus}</TableCell>
        {editMode && (
          <TableCell align="right">
            <Button
              className={classes.button}
              onClick={() => handleConformModal(entry.UDID)}
              size="small"
              variant="text"
            >
              Update
            </Button>
          </TableCell>
        )}
      </TableRow>
    );
  };

  useEffectOnlyFirst(fetchListOfProviders, [providers]);

  if (showNewPatch) {
    return (
      <>
        <Grid
          container
          spacing={2}
          justifyContentItems="center"
          alignContent="center"
        >
          <Grid item xs={12}>
            <Box sx={{ p: 2 }}>
              <Button
                onClick={() => setShowNewPatch(false)}
                className={classes.button}
                startIcon={<ChevronLeftIcon />}
                variant="outlined"
              >
                Back
              </Button>
            </Box>
          </Grid>
          <NewPatch
            cancel={() => {
              setShowNewPatch(false);
            }}
            providers={providers}
          />
        </Grid>
      </>
    );
  }
  return (
    <>
      <Fade in={!showNewPatch} mountOnEnter unmountOnExit>
        <div>
          <Box sx={{ width: "80vw", height: "50px" }}>
            {alertState.shouldShow && (
              <Grid container justifyContent="center">
                <Box sx={{ width: "25%" }}>
                  <Alert onClose={handleAlertClose} severity={alertState.sev}>
                    <Typography>{alertState.message}</Typography>
                  </Alert>
                </Box>
              </Grid>
            )}
          </Box>
          <Dialog open={showConformModal} onClose={handleConformModalClose}>
            <DialogTitle>Update Device Quality</DialogTitle>
            <DialogContent>
              <>
                <Box m={2}>
                  <TextField
                    id="conform-device-status-select"
                    select
                    label="Status"
                    value={deviceStatus}
                    className={classes.textField}
                    onChange={(event) => setDeviceStatus(event.target.value)}
                  >
                    {conformingstatuses.map((status) => (
                      <MenuItem
                        key={`conform-device-option${status}`}
                        value={status}
                      >
                        {status}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
                <Box m={2}>
                  <TextField
                    id="confrom-notes-input"
                    label="Notes"
                    value={conformnotes}
                    onChange={(event) => setConformNotes(event.target.value)}
                    multiline
                    rows={4}
                    className={classes.textField}
                  ></TextField>
                </Box>
              </>
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" onClick={handleConformModalClose}>
                Cancel
              </Button>

              <LoadingButton
                onClick={(event) => updateConform(event)}
                loading={conformloading}
                variant="contained"
              >
                Submit
              </LoadingButton>
            </DialogActions>
          </Dialog>
          <Dialog open={showProviderModal} onClose={handleProviderModalClose}>
            <DialogTitle>Assign New Provider</DialogTitle>
            <DialogContent>
              <Box m={2}>
                <TextField
                  id="Provider-device-status-select"
                  select
                  label="Provider"
                  value={newProvider}
                  className={classes.textField}
                  onChange={(event) => setNewProvider(event.target.value)}
                >
                  {providers.map((p) => (
                    <MenuItem
                      key={`provider-device-option${p.UPID}`}
                      value={p.ProvID}
                    >
                      {p.ProvID}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" onClick={handleProviderModalClose}>
                Cancel
              </Button>

              <LoadingButton
                onClick={() => updateDeviceProvider(selectedDevice)}
                loading={providerloading}
                variant="contained"
              >
                Submit
              </LoadingButton>
            </DialogActions>
          </Dialog>
          <Dialog open={showPsnModal} onClose={handlePsnModalClose}>
            <DialogTitle>Change PSN</DialogTitle>
            <DialogContent>
              <Box>
                <Typography>
                  {`old psn: ${
                    data?.find((d) => d.UDID === selectedDevice)?.ProdSerNum
                  }`}
                </Typography>
              </Box>
              <Box m={2}>
                <TextField
                  id="Product"
                  select
                  label="Product"
                  value={PLSn.P}
                  className={classes.textField}
                  onChange={(event) =>
                    setPLSn({ ...PLSn, P: event.target.value })
                  }
                >
                  {["CMSU0001", "CMSU0000", "CMSU0002"].map((p) => (
                    <MenuItem key={`product-device-option${p}`} value={p}>
                      {p}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
              <Box m={2}>
                <TextField
                  id="lotcode"
                  label="Lot Code"
                  value={PLSn.L}
                  className={classes.textField}
                  onChange={(event) =>
                    setPLSn({ ...PLSn, L: event.target.value })
                  }
                />
              </Box>
              <Box m={2}>
                <TextField
                  id="SerialNumber"
                  label="Serial Number"
                  value={PLSn.SN}
                  className={classes.textField}
                  onChange={(event) =>
                    setPLSn({ ...PLSn, SN: event.target.value })
                  }
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" onClick={handlePsnModalClose}>
                Cancel
              </Button>

              <LoadingButton
                onClick={() => updateDevicePSN(selectedDevice)}
                loading={isPsnLoading}
                variant="contained"
              >
                Submit
              </LoadingButton>
            </DialogActions>
          </Dialog>
          {/* <Modal
            open={showProviderModal}
            onClose={handleProviderModalClose}
            aria-labelledby="provider-modal-title"
            aria-describedby="provider-modal-description"
          >
            <Fade in={showProviderModal}>
              <div className={classes.modalPaper}>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  direction="row"
                >
                  <Grid item>
                    <Typography variant="h4">Assign New Provider</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Box m={2} />
                  </Grid>
                  <Grid item>
                    <Box m={2}>
                      <TextField
                        id="Provider-device-status-select"
                        select
                        label="Provider"
                        value={newProvider}
                        className={classes.textField}
                        onChange={(event) => setNewProvider(event.target.value)}
                      >
                        {providers.map((p) => (
                          <MenuItem
                            key={`provider-device-option${p.UPID}`}
                            value={p.ProvID}
                          >
                            {p.ProvID}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Box>
                  </Grid>

                  {providerloading && (
                    <Grid container item justifyContent="center">
                      <Box m={2}>
                        <CircularProgress size={50} />
                      </Box>
                    </Grid>
                  )}
                </Grid>
                <div className={classes.modalbottom}>
                  <Grid
                    container
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box m={2}>
                      <Button
                        onClick={(event) =>
                          updateDeviceProvider(selectedDevice)
                        }
                      >
                        Submit
                      </Button>
                    </Box>
                    <Box m={2}>
                      <Button
                        onClick={handleProviderModalClose}
                        variant="outlined"
                      >
                        Cancel
                      </Button>
                    </Box>
                  </Grid>
                </div>
              </div>
            </Fade>
          </Modal> */}
          <Container>
            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <Box sx={{ p: 4 }}>
                {!!providers && (
                  <>
                    <FormControl className={classes.textField}>
                      <InputLabel id="dropdown-select-provider-label">
                        {"Provider"}
                      </InputLabel>
                      <Select
                        labelId="dropdown-select-provider-label"
                        id="dropdown-select-provider"
                        label="Provider"
                        value={`${selectedProvider}`}
                        onChange={(event) =>
                          handleProviderSelect(event.target.value)
                        }
                      >
                        {providers.map((option, index) => {
                          return (
                            <MenuItem
                              key={`dropdown-${index}-${option}`}
                              value={option.UPID}
                            >
                              {`${option.Name} (${option.ProvID})`}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </>
                )}
              </Box>

              <Box sx={{ p: 2 }}>
                <Button
                  className={classes.button}
                  onClick={() => setShowNewPatch(true)}
                >
                  New
                </Button>
              </Box>
              {!editMode && (
                <Box m={2}>
                  <Button
                    className={classes.button}
                    onClick={() => setEditMode(true)}
                    variant="outlined"
                    disabled={isLoading}
                  >
                    Edit
                  </Button>
                </Box>
              )}
              {editMode && (
                <Box m={2}>
                  <Button
                    className={classes.button}
                    onClick={() => setEditMode(false)}
                  >
                    Done
                  </Button>
                </Box>
              )}
            </Grid>

            <Grid item container justifyContent="center">
              {isLoading && !!selectedProvider && (
                <>
                  <TableContainer component={Paper}>
                    <Table
                      sx={{ minWidth: 1020 }}
                      size="small"
                      className={classes.table}
                    >
                      <TableHead key="thead-skeliton">
                        <TableRow key="tr-head">
                          <TableCell align="left" width={150}>
                            Sensor ID
                          </TableCell>
                          <TableCell align="right" width={80}>
                            Provider ID
                          </TableCell>
                          <TableCell align="right" width={150}>
                            Client ID
                          </TableCell>
                          <TableCell align="right" width={200}>
                            Serial Num
                          </TableCell>
                          <TableCell align="right" width={300}>
                            UDID
                          </TableCell>
                          <TableCell align="right" width={165}>
                            Device Type
                          </TableCell>
                          <TableCell align="right" width={50}>
                            IsConforming
                          </TableCell>
                          <TableCell align="right" width={120}>
                            Conform status
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {patches.map((e) => getSkeletonFromEntry(e))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}
              {!isLoading && !!data && (
                <>
                  <TableContainer component={Paper}>
                    <Table
                      size="small"
                      sx={{ minWidth: 1020 }}
                      className={classes.table}
                    >
                      <TableHead key="thead">
                        <TableRow key="tr-head">
                          <TableCell align="left" width={150}>
                            Sensor ID
                          </TableCell>
                          <TableCell align="right" width={80}>
                            Provider ID
                          </TableCell>
                          <TableCell align="right" width={150}>
                            Client ID
                          </TableCell>
                          <TableCell align="right" width={200}>
                            Serial Num
                          </TableCell>
                          <TableCell align="right" width={300}>
                            UDID
                          </TableCell>
                          <TableCell align="right" width={165}>
                            Device Type
                          </TableCell>
                          <TableCell align="right" width={50}>
                            IsConforming
                          </TableCell>
                          <TableCell align="right" width={120}>
                            Conform status
                          </TableCell>
                          {editMode && (
                            <TableCell align="right" width={120}>
                              Conform
                            </TableCell>
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data.map((e) => getTableFromEntry(e))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}
            </Grid>
          </Container>
        </div>
      </Fade>
    </>
  );
};

export default PatchList;
