import React from "react";
import {
  XYPlot,
  XAxis,
  YAxis,
  HorizontalGridLines,
  LineSeries,
  MarkSeries,
} from "react-vis";
import { Paper } from "@mui/material";
const EcgChartVis = ({ traceData, configData }) => {
  const axisStyle = {
    ticks: {
      fontSize: "14px",
      color: "#333",
    },
    title: {
      fontSize: "16px",
      color: "#333",
    },
  };
  if (!traceData || !configData) {
    return <></>;
  }
  const { Xlabel, Ylabel } = configData.config;

  const data = traceData.Data.map((e, index) => {
    return { x: traceData.Time[index] / 1000, y: e };
  });

  const peaks =
    traceData?.BeatsValidRpeak_msec_uv !== undefined
      ? JSON.parse(traceData?.BeatsValidRpeak_msec_uv)
      : [];

  const peakData = peaks.map((e, index) => {
    return { x: e[0] / 1000, y: e[1] };
  });

  data.sort((first, second) => {
    return first[Ylabel] - second[Ylabel];
  });

  return (
    <Paper elevation={5}>
      <XYPlot xType="linear" yPadding={30} width={400} height={400} animation>
        <HorizontalGridLines tickTotal={10} />
        <XAxis tickTotal={10} title={Xlabel} style={axisStyle} />
        <YAxis tickLabelAngle={45} tickTotal={10} title={Ylabel} />
        <LineSeries data={data} color="Red" />
        <MarkSeries
          data={peakData}
          color="Black"
          size={5}
          opacity={0.5}
          getNull={(d) => {
            if (d.x < 12) {
              return true;
            }
            return false;
          }}
        />
      </XYPlot>
    </Paper>
  );
};

export default EcgChartVis;
