import React, { useState, useRef, useEffect } from "react";
import { Auth } from "aws-amplify";
import { MyButton as Button } from "./components";
import NewClient from "./newclient";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import styles from "./styles";
import Box from "@mui/material/Box";

const clients = Array.from(Array(15).keys());

const baseUrl = "https://pow9rz7r6d.execute-api.us-west-2.amazonaws.com/dev";

const useEffectOnlyFirst = (fn, arr) => {
  const isFirst = useRef(false);

  useEffect(() => {
    if (isFirst.current) {
      return;
    } else {
      isFirst.current = true;
      return fn();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, arr);
};

const ClientList = ({ selectedClient, setSelectedClient }) => {
  const [data, setData] = useState([]);
  const [providers, setProviders] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [clientID, setCID] = useState("");
  const [showNewClient, setShowNewClient] = useState(false);
  const classes = styles();

  const fetchListOfClients = async () => {
    setLoading(true);
    const user = await Auth.currentAuthenticatedUser();
    const endpointResp = await fetch(
      baseUrl + "/client?dev=ely&row_count=100",
      {
        method: "GET",
        headers: new Headers({
          Authorization: user.signInUserSession.idToken.jwtToken,
        }),
        cache: "no-cache",
      }
    );
    const json = await endpointResp.json();
    setData(json.body);
    setFilteredData(json.body);
    setLoading(false);
  };
  const fetchListOfProviders = async () => {
    setLoading(true);
    const user = await Auth.currentAuthenticatedUser();
    const endpointResp = await fetch(baseUrl + "/provider", {
      method: "GET",
      headers: new Headers({
        Authorization: user.signInUserSession.idToken.jwtToken,
      }),
      cache: "no-cache",
    });
    const json = await endpointResp.json();
    setProviders(json.body);
    setLoading(false);
  };

  const updateFilter = (filter) => {
    setCID(filter);
    if (data !== undefined) {
      if (filter === "" || filter === undefined) setFilteredData(data);
      const filtered = data.filter(
        (e) =>
          e?.UCID.includes(filter.toLowerCase()) ||
          e?.Name.toLowerCase().includes(filter.toLowerCase()) ||
          e?.ProvID === parseInt(filter)
      );
      setFilteredData(filtered);
    }
  };
  const getSkeletonFromEntry = (rowNum) => {
    return (
      <TableRow key={`skeleton-row-${rowNum}`}>
        <TableCell align="right" width={50}>
          <Skeleton variant="circular" width={40} height={40} />
        </TableCell>
        <TableCell align="right" width={200}>
          <Grid container justifyContent="center" alignItems="center">
            <Skeleton variant="rectangular" width={200} height={36} />
          </Grid>
        </TableCell>
        <TableCell align="right" width={32}>
          <Skeleton />
        </TableCell>
        <TableCell align="right" width={120}>
          <Skeleton />
        </TableCell>
        <TableCell align="right" width={120}>
          <Grid container justifyContent="right" alignItems="center">
            <Skeleton width={80} />
          </Grid>
        </TableCell>
        <TableCell align="right" width={150}>
          <Skeleton />
        </TableCell>
        <TableCell align="right" width={50}>
          <Skeleton />
        </TableCell>
      </TableRow>
    );
  };
  const getTableFromEntry = (entry) => {
    return (
      <TableRow
        key={`client-list-${entry.index}`}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell align="right">
          <Avatar
            alt={entry.Name}
            src={entry.PhotoUrl}
            className={classes.imageAvatar}
          />
        </TableCell>
        <TableCell sx={{ width: 200 }} align="center">
          {selectedClient === entry.UCID ? (
            <Box
              sx={{
                height: 30,
                mt: "10px",
              }}
            >
              <Typography variant="p">{`${entry.Name}`}</Typography>
            </Box>
          ) : (
            <Button
              sx={{ width: 200 }}
              variant="text"
              onClick={() => setSelectedClient(entry.UCID)}
            >
              {entry.Name}
            </Button>
          )}
        </TableCell>
        <TableCell align="right">{`${
          entry.AlertCountBySeverity !== undefined
            ? entry.AlertCountBySeverity.High
            : ""
        }`}</TableCell>
        <TableCell align="right">{entry.UCID}</TableCell>
        <TableCell align="right">{entry.ClientID}</TableCell>
        <TableCell align="right">
          {entry?.AlertConfig?.IsEnabled ? "true" : false || "---"}
        </TableCell>
        <TableCell align="right">{entry.ProvID}</TableCell>
      </TableRow>
    );
  };

  useEffectOnlyFirst(fetchListOfClients, [data]);
  useEffectOnlyFirst(fetchListOfProviders, [providers]);

  if (showNewClient) {
    return (
      <>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignContent="center"
        >
          <Grid item xs={12}>
            <Box sx={{ p: 2 }}>
              <Button
                onClick={() => setShowNewClient(false)}
                className={classes.button}
                startIcon={<ChevronLeftIcon />}
                variant="outlined"
              >
                Back
              </Button>
            </Box>
          </Grid>

          <NewClient
            cancel={() => {
              setShowNewClient(false);
            }}
            providers={providers}
          />
        </Grid>
      </>
    );
  }
  return (
    <>
      <Fade in={!showNewClient} mountOnEnter unmountOnExit>
        <div>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Box sx={{ p: 2 }}>
              <Button disabled onClick={() => setShowNewClient(true)}>
                Add Client
              </Button>
            </Box>

            <Box sx={{ p: 2 }}>
              <TextField
                className={classes.textField}
                id="searchClient"
                label="Search"
                variant="outlined"
                value={clientID}
                onChange={(event) => updateFilter(event.target.value)}
              />
            </Box>
            {filteredData.length !== 0 && (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 800 }} size="small">
                  <TableHead key="thead">
                    <TableRow key={"tr-head"}>
                      <TableCell align="left" width={50}>
                        Client
                      </TableCell>
                      <TableCell align="center" width={200}>
                        Name
                      </TableCell>
                      <TableCell align="right" width={32}>
                        AlertCount
                      </TableCell>
                      <TableCell align="right" width={120}>
                        Client UCID
                      </TableCell>
                      <TableCell align="right" width={120}>
                        cid
                      </TableCell>
                      <TableCell align="right" width={150}>
                        Alert Config Is Enabled
                      </TableCell>
                      <TableCell align="right" width={50}>
                        ProvID
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {filteredData.map((e) => getTableFromEntry(e))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            {(filteredData.length === 0 || isLoading) && (
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 800 }}
                  size="small"
                  className={classes.table}
                >
                  <TableHead key="thead">
                    <TableRow key={"tr-head"}>
                      <TableCell align="left" width={50}>
                        Client
                      </TableCell>
                      <TableCell align="center" width={200}>
                        Name
                      </TableCell>
                      <TableCell align="right" width={32}>
                        AlertCount
                      </TableCell>
                      <TableCell align="right" width={120}>
                        Client UCID
                      </TableCell>
                      <TableCell align="right" width={120}>
                        cid
                      </TableCell>
                      <TableCell align="right" width={150}>
                        Alert Config Is Enabled
                      </TableCell>
                      <TableCell align="right" width={50}>
                        ProvID
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {clients.map((e) => getSkeletonFromEntry(e))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
        </div>
      </Fade>
    </>
  );
};

export default ClientList;
