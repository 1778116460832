import React, { useState, useRef, useEffect } from "react";
import { Auth } from "aws-amplify";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import Skeleton from "@mui/material/Skeleton";
import ReportChart from "./reportchart";

const baseUrl = "https://pow9rz7r6d.execute-api.us-west-2.amazonaws.com/dev";

const useEffectOnlyFirst = (fn, arr) => {
  const isFirst = useRef(false);

  useEffect(() => {
    if (isFirst.current) {
      return;
    } else {
      isFirst.current = true;
      return fn();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, arr);
};

const Reports = () => {
  const [reports, setReports] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [reportIndex, setReportIndex] = useState(0);

  const fetchReports = async () => {
    setIsLoading(true);
    const user = await Auth.currentAuthenticatedUser();
    const endpointResp = await fetch(
      baseUrl + "/report/list?type=DailyVital&ProvID=200",
      {
        method: "GET",
        headers: new Headers({
          Authorization: user.signInUserSession.idToken.jwtToken,
        }),
        cache: "no-cache",
      }
    );
    const json = await endpointResp.json();
    setReports(json.body);
    setIsLoading(false);
  };

  useEffectOnlyFirst(fetchReports, [reports]);

  const handleNext = (event) => {
    event.preventDefault();
    setReportIndex(reportIndex + 1);
  };

  const handlePrev = () => {
    setReportIndex(reportIndex - 1);
  };

  const borderRadius = 5;
  const imageHeight = 220;
  const imageWidth = 550;

  return (
    <Grid container spacing={2}>
      <Grid item container xs={1} alignItems="center">
        <IconButton
          disabled={reportIndex === 0}
          onClick={handlePrev}
          sx={{ width: 150, height: 150 }}
          color="primary"
        >
          <ChevronLeftRoundedIcon sx={{ fontSize: 150 }} />
        </IconButton>
      </Grid>
      <Grid item xs={10} container alignItems="center" justifyContent="center">
        <Box sx={{ width: 1200, height: 50 }}>
          <Grid container direction="row" justifyContent="center">
            <Grid
              item
              xs={4}
              container
              justifyContent="left"
              alignItems="center"
            >
              {!isLoading && (
                <Typography
                  fontSize={25}
                >{`${reports[reportIndex]?.ClientName}`}</Typography>
              )}
            </Grid>
            <Grid
              item
              xs={4}
              container
              justifyContent="center"
              alignItems="center"
            >
              <IconButton
                disabled={reportIndex === 0}
                onClick={handlePrev}
                color="primary"
                sx={{ width: 32, height: 32 }}
              >
                <ChevronLeftRoundedIcon sx={{ fontSize: 50 }} />
              </IconButton>
              <Typography>{`${reportIndex + 1} of ${
                reports.length
              }`}</Typography>
              <IconButton
                disabled={reportIndex === reports.length - 1}
                onClick={handleNext}
                sx={{ width: 32, height: 32 }}
                color="primary"
              >
                <ChevronRightRoundedIcon sx={{ fontSize: 50 }} />
              </IconButton>
            </Grid>
            <Grid item xs={4} container justifyContent="right">
              <Box sx={{ mr: 2 }}>
                <Button variant="contained">Print</Button>
              </Box>
              <Box sx={{ mr: 2 }}>
                <Button variant="contained">Email</Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <>
          {isLoading && (
            <>
              <Paper elevation={5} sx={{ width: 1200, height: 750 }}>
                <Skeleton
                  variant="rectangular"
                  active
                  width={"1200px"}
                  animation="wave"
                  height={"750px"}
                ></Skeleton>
              </Paper>
            </>
          )}
          {!isLoading && !!reports && (
            <>
              <Paper elevation={5} sx={{ width: 1200, height: 750 }}>
                <Grid container item sx={6} justifyContent="center">
                  <Stack direction="column">
                    <ReportChart
                      imageWidth={imageWidth}
                      imageHeight={imageHeight}
                      borderRadius={borderRadius}
                      reportUrl={reports[reportIndex]?.Charts["HRBasic"] || ""}
                    />
                    <ReportChart
                      imageWidth={imageWidth}
                      imageHeight={imageHeight}
                      borderRadius={borderRadius}
                      reportUrl={reports[reportIndex]?.Charts["RRBasic"] || ""}
                    />

                    <ReportChart
                      imageWidth={imageWidth}
                      imageHeight={imageHeight}
                      borderRadius={borderRadius}
                      reportUrl={
                        reports[reportIndex]?.Charts["TempBasic"] || ""
                      }
                    />
                  </Stack>

                  <Stack direction="column">
                    <ReportChart
                      imageWidth={imageWidth}
                      imageHeight={imageHeight}
                      borderRadius={borderRadius}
                      reportUrl={reports[reportIndex]?.Charts["HRCandle"] || ""}
                    />
                    <ReportChart
                      imageWidth={imageWidth}
                      imageHeight={imageHeight}
                      borderRadius={borderRadius}
                      reportUrl={reports[reportIndex]?.Charts["RRCandle"] || ""}
                    />
                    <ReportChart
                      imageWidth={imageWidth}
                      imageHeight={imageHeight}
                      borderRadius={borderRadius}
                      reportUrl={
                        reports[reportIndex]?.Charts["TempCandle"] || ""
                      }
                    />
                  </Stack>
                </Grid>
              </Paper>
            </>
          )}
        </>
      </Grid>
      <Grid item xs={1} container alignItems="center" justifyContent="center">
        <IconButton
          disabled={reportIndex === reports.length - 1}
          onClick={handleNext}
          sx={{ width: 150, height: 150 }}
          color="primary"
        >
          <ChevronRightRoundedIcon sx={{ fontSize: 150 }} />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default Reports;
