import React, { useState, useRef, useEffect } from "react";
import { Auth } from "aws-amplify";
import { MyButton as Button } from "./components";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import Fade from "@mui/material/Fade";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "./alertcomponent";
import styles from "./styles";

const baseUrl = "https://pow9rz7r6d.execute-api.us-west-2.amazonaws.com/dev";

const NewGateway = ({ cancel, providers, refresh }) => {
  const [isLoading, setLoading] = useState(false);

  //Form data
  const [selectedProvider, setSelectedProvider] = useState("0");
  const [gatewayid, setGatewayID] = useState("");
  const [uuid, setUUID] = useState("");

  //Product data

  const [lotcode, setLotCode] = useState("");
  const [manufacturer, setManufacturer] = useState("Ai2");
  const [serialnum, setSerialNumber] = useState("");
  const [modelnum, setModelNumber] = useState("");
  const [builddate, setBuildDate] = useState("");
  const [productnotes, setProductNotes] = useState("");

  const [alertState, setAlertState] = useState({ shouldShow: false });

  const classes = styles();

  const useEffectOnlyFirst = (fn, arr) => {
    const isFirst = useRef(false);

    useEffect(() => {
      if (isFirst.current) {
        return;
      } else {
        isFirst.current = true;
        return fn();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, arr);
  };
  const handleNewGateway = async (event) => {
    setLoading(true);

    event.preventDefault();
    const user = await Auth.currentAuthenticatedUser();
    const upload = await fetch(baseUrl + "/admin/superuser/gateway", {
      method: "POST",
      headers: new Headers({
        Authorization: user.signInUserSession.idToken.jwtToken,
        "Content-Type": "application/json",
      }),
      cache: "no-cache",
      body: JSON.stringify({
        provider: selectedProvider,
        gwid: gatewayid,
        uuid: uuid,
        lotcode: lotcode,
        manufacturer: manufacturer,
        serial: serialnum,
        model: modelnum,
        builddate: builddate,
        productnotes: productnotes,
      }),
    });
    const json = await upload.json();
    if ("Error" in json) {
      console.log(json.Error);
      setAlertState({
        message: json.Error,
        sev: "error",
        shouldShow: true,
      });
    } else {
      console.log(json.body[0]);
      if (json.body[0].WasSuccess) {
        setAlertState({
          message: "Gateway Successfully Created!",
          sev: "success",
          shouldShow: true,
        });
      }
    }

    setLoading(false);
    refresh(false);
  };

  useEffectOnlyFirst(() => {
    fetchUUID();
  }, [uuid]);

  const fetchUUID = async () => {
    setLoading(true);
    setUUID("");
    const user = await Auth.currentAuthenticatedUser();
    const uuid_result = await fetch(
      baseUrl + "/admin/superuser/uuid?type=Device",
      {
        method: "GET",
        headers: new Headers({
          Authorization: user.signInUserSession.idToken.jwtToken,
        }),
        cache: "no-cache",
      }
    );
    const json = await uuid_result.json();
    setUUID(json.body[0].uuid[0]);
    setLoading(false);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertState({ shouldShow: false });
  };

  return (
    <>
      <Fade in={true} mountOnEnter unmountOnExit>
        <div className={classes.paperContent}>
          <Box sx={{ width: "80vw", height: "50px", p: 2 }}>
            {alertState.shouldShow && (
              <Grid container justifyContent="center">
                <Box sx={{ width: "25%" }}>
                  <Alert onClose={handleAlertClose} severity={alertState.sev}>
                    <Typography>{alertState.message}</Typography>
                  </Alert>
                </Box>
              </Grid>
            )}
          </Box>
          <form noValidate autoComplete="off">
            <Paper sx={{ width: "80vw" }}>
              <Grid container justifyContent="center" alignContent="center">
                <Box sx={{ p: 2 }}>
                  <Typography variant="h3">Add Gateway</Typography>
                </Box>
                <Grid container item justifyContent="center">
                  <Box sx={{ p: 2 }}>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="dropdown-select-provider-label">
                        {"Provider"}
                      </InputLabel>

                      <Select
                        className={classes.textField}
                        labelId="dropdown-select-provider-label"
                        label={"Provider"}
                        id="dropdown-select-provider"
                        value={`${selectedProvider}`}
                        onChange={(event) =>
                          setSelectedProvider(event.target.value)
                        }
                        required
                      >
                        {providers.map((option, index) => {
                          return (
                            <MenuItem
                              key={`dropdown-${index}-${option.ProvID}`}
                              value={option.ProvID}
                            >
                              {`${option.Name} (${option.ProvID})`}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box m={2}>
                    <TextField
                      className={classes.textField}
                      id="gwid-input"
                      label="Gatway ID"
                      value={gatewayid}
                      onChange={(event) => setGatewayID(event.target.value)}
                      required
                    />
                  </Box>

                  <Box m={2}>
                    <TextField
                      className={classes.uuidtextField}
                      id="uuid-input"
                      label="UUID"
                      value={uuid}
                      readOnly
                      // onChange={(event) => setSelectedClient(event.target.value)}
                    />
                  </Box>
                </Grid>

                <Typography variant="h5">Product</Typography>
                <Grid container item justifyContent="center">
                  <Box m={2}>
                    <TextField
                      className={classes.textField}
                      id="LotCode-input"
                      label="Lot Code"
                      value={lotcode}
                      onChange={(event) => setLotCode(event.target.value)}
                    />
                  </Box>
                  <Box m={2}>
                    <TextField
                      className={classes.textField}
                      id="Manufacturer-input"
                      label="Manufacturer"
                      value={manufacturer}
                      onChange={(event) => setManufacturer(event.target.value)}
                    />
                  </Box>
                  <Box m={2}>
                    <TextField
                      className={classes.textField}
                      id="Serialno-input"
                      label="Serial Number"
                      value={serialnum}
                      onChange={(event) => setSerialNumber(event.target.value)}
                    />
                  </Box>
                  <Box m={2}>
                    <TextField
                      className={classes.textField}
                      id="Model-number-input"
                      label="Model-number"
                      value={modelnum}
                      onChange={(event) => setModelNumber(event.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid container item justifyContent="center">
                  <Box m={2}>
                    <TextField
                      className={classes.textField}
                      id="buildDate-input"
                      type="Date"
                      label="Build Date"
                      value={builddate}
                      onChange={(event) => setBuildDate(event.target.value)}
                    />
                  </Box>

                  <Box m={2}>
                    <TextField
                      className={classes.textField}
                      id="product-note-input"
                      type="text"
                      label="Notes"
                      value={productnotes}
                      onChange={(event) => setProductNotes(event.target.value)}
                    />
                  </Box>
                </Grid>
                <Box m={2}>
                  <Button onClick={(event) => handleNewGateway(event)}>
                    Submit
                  </Button>
                </Box>
                <Box m={2}>
                  <Button variant="outlined" onClick={cancel}>
                    Cancel
                  </Button>
                </Box>
              </Grid>
              <Grid item>{isLoading && <CircularProgress size={100} />}</Grid>
            </Paper>
          </form>
        </div>
      </Fade>
    </>
  );
};

export default NewGateway;
