import React, { useState, useRef, useEffect } from "react";
import { Auth } from "aws-amplify";
import { MyButton as Button } from "./components";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import Fade from "@mui/material/Fade";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "./alertcomponent";
import Stack from "@mui/material/Stack";

import styles from "./styles";

const baseUrl = "https://pow9rz7r6d.execute-api.us-west-2.amazonaws.com/dev";

const NewPatch = ({ cancel, providers }) => {
  const [isLoading, setLoading] = useState(false);

  //Form data
  const [selectedProvider, setSelectedProvider] = useState("0");
  const [deviceid, setDeivceID] = useState("");
  const [devicetype, setDeviceType] = useState("Unassigned");
  const [uuid, setUUID] = useState("");

  //Product data

  const [lotcode, setLotCode] = useState("");
  const [manufacturer, setManufacturer] = useState("Ai2");
  const [serialnum, setSerialNumber] = useState("");
  const [modelnum, setModelNumber] = useState("");
  const [builddate, setBuildDate] = useState("");
  const [productnotes, setProductNotes] = useState("");

  const [alertState, setAlertState] = useState({ shouldShow: false });

  const classes = styles();

  const devicetypes = [
    "Unassigned",
    "Ai2PatchR00",
    "Ai2PatchCMSU0001",
    "Ai2PatchCMSU0002",
    "AndesBloodPresR00",
    "AndesPulseOximR00",
    "AndesEarThermoR00",
    "AndesScaleR00",
    "AndesIrThermoR00",
    "AndesGlucoseR00",
  ];

  const useEffectOnlyFirst = (fn, arr) => {
    const isFirst = useRef(false);

    useEffect(() => {
      if (isFirst.current) {
        return;
      } else {
        isFirst.current = true;
        return fn();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, arr);
  };
  const handleNewPatch = async (event) => {
    setLoading(true);

    event.preventDefault();
    const user = await Auth.currentAuthenticatedUser();
    const upload = await fetch(baseUrl + "/admin/superuser/patch", {
      method: "POST",
      headers: new Headers({
        Authorization: user.signInUserSession.idToken.jwtToken,
        "Content-Type": "application/json",
      }),
      cache: "no-cache",
      body: JSON.stringify({
        provider: selectedProvider,
        deviceid: deviceid,
        devicetype: devicetype,
        udid: uuid,
        lotcode: lotcode,
        manufacturer: manufacturer,
        serial: serialnum,
        model: modelnum,
        builddate: builddate,
        productnotes: productnotes,
      }),
    });
    const json = await upload.json();
    if ("Error" in json) {
      console.log(json.Error);
      setAlertState({
        message: json.Error,
        sev: "error",
        shouldShow: true,
      });
    } else {
      console.log(json.body[0]);
      if (json.body[0].WasSuccess) {
        setAlertState({
          message: "Sensor Successfully Created!",
          sev: "success",
          shouldShow: true,
        });
      }
    }

    setLoading(false);
  };

  useEffectOnlyFirst(() => {
    fetchUUID();
  }, [uuid]);

  const fetchUUID = async () => {
    setLoading(true);
    setUUID("");
    const user = await Auth.currentAuthenticatedUser();
    const uuid_result = await fetch(
      baseUrl + "/admin/superuser/uuid?type=Device",
      {
        method: "GET",
        headers: new Headers({
          Authorization: user.signInUserSession.idToken.jwtToken,
        }),
        cache: "no-cache",
      }
    );
    const json = await uuid_result.json();
    setUUID(json.body[0].uuid[0]);
    setLoading(false);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertState({ shouldShow: false });
  };

  return (
    <>
      <Fade in={true} mountOnEnter unmountOnExit>
        <div className={classes.paperContent}>
          <Box sx={{ width: "80vw", height: "50px", p: 2 }}>
            {alertState.shouldShow && (
              <Grid container justifyContent="center">
                <Box sx={{ width: "25%" }}>
                  <Alert onClose={handleAlertClose} severity={alertState.sev}>
                    <Typography>{alertState.message}</Typography>
                  </Alert>
                </Box>
              </Grid>
            )}
          </Box>
          <form noValidate autoComplete="off">
            <Paper sx={{ width: "80vw" }}>
              <Grid container justifyContent="center" alignContent="center">
                <Box sx={{ p: 2 }}>
                  <Typography variant="h3">Add Sensor</Typography>
                </Box>
                <Grid container item justifyContent="center">
                  <Box sx={{ p: 2 }}>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="dropdown-select-provider-label">
                        {"Provider"}
                      </InputLabel>

                      <Select
                        className={classes.textField}
                        labelId="dropdown-select-provider-label"
                        label={"Provider"}
                        id="dropdown-select-provider"
                        value={`${selectedProvider}`}
                        onChange={(event) =>
                          setSelectedProvider(event.target.value)
                        }
                        required
                      >
                        {providers.map((option, index) => {
                          return (
                            <MenuItem
                              key={`dropdown-${index}-${option.ProvID}`}
                              value={option.ProvID}
                            >
                              {`${option.Name} (${option.ProvID})`}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box m={2}>
                    <TextField
                      className={classes.textField}
                      id="deviceid-input"
                      label="DeviceID"
                      value={deviceid}
                      onChange={(event) => setDeivceID(event.target.value)}
                      required
                    />
                  </Box>

                  <Box m={2}>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="dropdown-select-device-type">
                        {"Device Type"}
                      </InputLabel>

                      <Select
                        className={classes.textField}
                        labelId="dropdown-select-device-type"
                        id="dropdown-select-device-type"
                        label={"Device Type"}
                        value={`${devicetype}`}
                        onChange={(event) => setDeviceType(event.target.value)}
                      >
                        {devicetypes.map((option, index) => {
                          return (
                            <MenuItem
                              key={`dropdown-${index}-${option}`}
                              value={option}
                            >
                              {option}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>

                  <Box m={2}>
                    <TextField
                      className={classes.uuidtextField}
                      id="uuid-input"
                      label="UUID"
                      value={uuid}
                      readOnly
                      // onChange={(event) => setSelectedClient(event.target.value)}
                    />
                  </Box>
                </Grid>
                <>
                  <Box sx={{ p: 2 }}>
                    <Typography variant="h5">Product</Typography>
                  </Box>
                  <Grid container item justifyContent="center">
                    <Box m={2}>
                      <TextField
                        className={classes.textField}
                        id="LotCode-input"
                        label="Lot Code"
                        value={lotcode}
                        onChange={(event) => setLotCode(event.target.value)}
                      />
                    </Box>
                    <Box m={2}>
                      <TextField
                        className={classes.textField}
                        id="Manufacturer-input"
                        label="Manufacturer"
                        value={manufacturer}
                        onChange={(event) =>
                          setManufacturer(event.target.value)
                        }
                      />
                    </Box>
                    <Box m={2}>
                      <TextField
                        className={classes.textField}
                        id="Serialno-input"
                        label="Serial Number"
                        value={serialnum}
                        onChange={(event) =>
                          setSerialNumber(event.target.value)
                        }
                      />
                    </Box>
                    <Box m={2}>
                      <TextField
                        className={classes.textField}
                        id="Model-number-input"
                        label="Model-number"
                        value={modelnum}
                        onChange={(event) => setModelNumber(event.target.value)}
                      />
                    </Box>
                  </Grid>
                </>
                <>
                  <Grid container item justifyContent="center">
                    <Box m={2}>
                      <TextField
                        className={classes.textField}
                        id="buildDate-input"
                        type="Date"
                        label="Build Date"
                        value={builddate}
                        onChange={(event) => setBuildDate(event.target.value)}
                      />
                    </Box>

                    <Box m={2}>
                      <TextField
                        className={classes.textField}
                        id="product-note-input"
                        type="text"
                        label="Notes"
                        value={productnotes}
                        onChange={(event) =>
                          setProductNotes(event.target.value)
                        }
                      />
                    </Box>
                  </Grid>
                </>
                <Stack direction={"row"} justifyContent="center">
                  <Box m={2}>
                    <Button onClick={(event) => handleNewPatch(event)}>
                      Submit
                    </Button>
                  </Box>
                  <Box m={2}>
                    <Button variant="outlined" onClick={cancel}>
                      Cancel
                    </Button>
                  </Box>
                </Stack>
              </Grid>
              <Grid item>{isLoading && <CircularProgress size={100} />}</Grid>
            </Paper>
          </form>
        </div>
      </Fade>
    </>
  );
};

export default NewPatch;
